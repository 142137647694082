import { IShiftSpec, IShiftsTab } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterShifts {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async list(
    year: number,
    month: number,
    ...groups: string[]
  ): Promise<IShiftSpec[]> {
    groups = [...new Set(groups)];
    groups.sort();
    if (month === 0) {
      year = year - 1;
      month = 12;
    }
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`shifts-${groups.join('-')}-${iso_month}`, async () => {
      return (
        await this.fetch(
          `api/roster/shift?type=group&month=${iso_month}${groups.map((id) => `&id=${id}`).join('')}`
        )
      ).json();
    });
  }

  async peek(
    year: number,
    month: number,
    ...groups: string[]
  ): Promise<IShiftSpec[]> {
    groups = [...new Set(groups)];
    groups.sort();
    if (month === 0) {
      year = year - 1;
      month = 12;
    }
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(
      `shifts-peek-${groups.join('-')}-${iso_month}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/shift?type=group&month=${iso_month}&readonly=true${groups.map((id) => `&id=${id}`).join('')}`
          )
        ).json();
      }
    );
  }

  async for(
    year: number,
    month: number,
    userId?: string
  ): Promise<IShiftSpec[]> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`shifts-for-${iso_month}-${userId}`, async () => {
      return (
        await this.fetch(
          `api/roster/shift?type=user&month=${iso_month}&id=${userId || 'my'}`
        )
      ).json();
    });
  }

  async tabs(
    year: number,
    month: number,
    groupId: string
  ): Promise<IShiftsTab[]> {
    if (month === 0) {
      year = year - 1;
      month = 12;
    }
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`shifts-tabs-${groupId}-${iso_month}`, async () => {
      return (
        await this.fetch(
          `api/roster/shift/tabs?month=${iso_month}&id=${groupId}`
        )
      ).json();
    });
  }

  async update(
    year: number,
    month: number,
    groupId: string,
    tabs: IShiftsTab[],
    shifts: IShiftSpec[]
  ): Promise<{ tabs: IShiftsTab[]; shifts: IShiftSpec[] }> {
    const specs = {
      tabs,
      shifts: shifts.map((shift) => ({
        id: shift.id,
        name: shift.name,
        start: shift.start,
        duration: shift.duration,
        created: shift.created,
        tab: shift.tab,
        order: shift.order,
        abbr: shift.abbr
      }))
    };
    const date = new Date(year, month - 1).toLocaleISOMonth();
    const url = `api/roster/shift?month=${date}&id=${groupId}`;
    const body = JSON.stringify(specs);
    const response = await this.fetch(url, {
      method: 'PUT',
      body
    });
    cache.clear(
      `shifts-${groupId}-${new Date(year, month - 1).toLocaleISOMonth()}`
    );
    return response.json();
  }
}
