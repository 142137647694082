export interface LinkButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  size?: 'sm' | 'md';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const LinkButton = (props: LinkButtonProps) => {
  const size = props.size ?? 'sm';
  const fontSize = size === 'sm' ? 'text-sm' : 'text-base';
  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      props.onClick?.({} as React.MouseEvent<HTMLButtonElement>);
    }
  };
  return (
    <button
      className={`hover:text-secondary outline-focus rounded px-1 pb-1 ${fontSize} underline underline-offset-4 outline-offset-4 disabled:opacity-40`}
      disabled={props.disabled}
      onClick={props.onClick}
      onKeyDown={handleKeyDown.bind(this)}
    >
      {props.children}
    </button>
  );
};
