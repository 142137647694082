import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  DialogConfirmUnsaved,
  DialogContext,
  GhostButton,
  Tabs
} from '@kidsmanager/ui-core';
import {
  hasModule,
  IEmailState,
  IGroupSummary,
  IRosterTemplate,
  IFullUser,
  IUserAllowance,
  Modules
} from '@kidsmanager/util-models';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  OutletContextAdminUser,
  SelectedUserAllowances
} from './outlet-context-admin-user';

export interface AdminUserDetailProps {
  selectedUser: IFullUser;
  modules: number;
  emailState?: IEmailState;
  groups: IGroupSummary[];
  templates: IRosterTemplate[];
  activeUsers: IFullUser[];
  lockedUsers: IFullUser[];
  onPersist?: (user: IFullUser) => void;
  onClose?: () => void;
}

const formatUsername = (lastName?: string, firstName?: string): string => {
  if (!firstName) {
    return lastName || '';
  }
  return `${lastName}, ${firstName}`;
};

const openAllowanceSum = (allowances?: IUserAllowance[]) => {
  if (!allowances) {
    return 0;
  }
  return allowances.filter((a) => !a.to).reduce((acc, a) => acc + a.value, 0);
};

export const AdminUserDetail = (props: AdminUserDetailProps) => {
  const [hasChanges, setHasChanges] = useState({
    user: false,
    allowances: false
  });
  const [currentUser, setCurrentUser] = useState<IFullUser | undefined>();
  const [allowances, setAllowances] = useState<SelectedUserAllowances>();
  const [defaultAllowances, setDefaultAllowances] =
    useState<SelectedUserAllowances>();

  const client = useContext(ClientBackendContext);
  const dialog = useContext(DialogContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (props.selectedUser?.userId !== currentUser?.userId) {
      if (hasChanges.user || hasChanges.allowances) {
        dialog.open(
          <DialogConfirmUnsaved
            onCancel={() => {
              dialog.close();
              navigate(-1);
            }}
            onConfirm={() => {
              dialog.close();
              setHasChanges({ user: false, allowances: false });
              setCurrentUser({ ...props.selectedUser });
            }}
          />
        );
      } else {
        setCurrentUser({ ...props.selectedUser });
      }
    } else if (!currentUser?.userId) {
      setCurrentUser({ ...props.selectedUser });
    }
  }, [props.selectedUser, currentUser, hasChanges, dialog, navigate]);

  useEffect(() => {
    if (!currentUser?.userId) {
      return;
    }
    client.admin.users.allowances(currentUser?.userId).then((value) => {
      const currentAllowances: SelectedUserAllowances = {
        hours: structuredClone(value.hours) || [],
        hoursInvalid: false,
        holidays: structuredClone(value.holiday) || [],
        holidaysInvalid: false
      };
      setAllowances(currentAllowances);
      setDefaultAllowances(currentAllowances);
    });
  }, [client, currentUser?.userId]);

  const newUser = useMemo(() => currentUser?.userId === 'new', [currentUser]);
  const idpLookup = useMemo(
    () => location.pathname.includes('/link/'),
    [location]
  );

  const tabs = useMemo(() => {
    const t = [
      { path: 'profile', title: 'Profil' },
      { path: 'permissions', title: 'Berechtigungen' }
    ];
    if (hasModule(props.modules, Modules.Timesheets)) {
      t.push({ path: 'shifts', title: 'Dienste' });
    }
    t.push({ path: 'access', title: 'Zugang' });
    return t;
  }, [props.modules]);

  const handleSelectUsername = (e: React.MouseEvent<HTMLSpanElement>) => {
    const range = document.createRange();
    const selection = window.getSelection();
    selection?.removeAllRanges();
    range.selectNodeContents(e.currentTarget);
    selection?.addRange(range);
  };

  const handleUserChange = (
    user: Partial<IFullUser>,
    hasValidationErrors: boolean
  ) => {
    setCurrentUser((prev) => {
      const modifiedUser = prev ? { ...prev, ...user } : prev;
      const isDifferent =
        JSON.stringify(modifiedUser) !== JSON.stringify(props.selectedUser);
      setHasChanges((prev) => ({
        allowances: prev.allowances,
        user: !hasValidationErrors && isDifferent
      }));

      return modifiedUser;
    });
  };

  const handleAllowancesChange = (value: Partial<SelectedUserAllowances>) => {
    setAllowances((prev) => {
      const modifiedAllowances = prev ? { ...prev, ...value } : prev;
      setHasChanges((prev) => ({
        user: prev.allowances,
        allowances:
          JSON.stringify(modifiedAllowances) !==
          JSON.stringify(defaultAllowances)
      }));
      return modifiedAllowances;
    });
    const partialUser: Partial<IFullUser> = {};
    if (value.hours && !value.hoursInvalid) {
      partialUser.hoursPerWeek = openAllowanceSum(value.hours);
    }
    if (value.holidays && !value.holidaysInvalid) {
      partialUser.holidayAllowance = openAllowanceSum(value.holidays);
    }
    handleUserChange(partialUser, false);
  };

  const handleSave = async () => {
    // maybe pull in the logic from admin-user to create,update the user and notify admin-user parent with the updated selected user
    setHasChanges({ user: false, allowances: false });
    if (currentUser) {
      props.onPersist?.(currentUser);
    }

    if (currentUser && hasModule(props.modules, Modules.Timesheets)) {
      const updates: Promise<IUserAllowance[] | null>[] = [];
      if (allowances?.hours && !allowances.hoursInvalid) {
        updates.push(
          client.admin.users.updateAllowances(
            currentUser.userId,
            'hours',
            allowances?.hours
          )
        );
      }
      if (allowances?.holidays && !allowances.holidaysInvalid) {
        updates.push(
          client.admin.users.updateAllowances(
            currentUser.userId,
            'holiday',
            allowances?.holidays
          )
        );
      }
      if (updates.length) {
        await Promise.all(updates);
      }
    }
  };

  const handleCancel = () => {
    if (newUser) {
      props.onClose?.();
    } else {
      setCurrentUser(structuredClone(props.selectedUser));
      setAllowances(structuredClone(defaultAllowances));
      setHasChanges({ user: false, allowances: false });
      document.dispatchEvent(
        new CustomEvent('adminUserAction', {
          detail: { reset: true, saved: false }
        })
      );
    }
  };

  return idpLookup ? (
    <Outlet
      context={
        {
          newUser,
          selectedUser: currentUser,
          userId: props.selectedUser.userId,
          modules: props.modules,
          groups: props.groups,
          templates: props.templates,
          activeUsers: props.activeUsers,
          lockedUsers: props.activeUsers,
          emailState: props.emailState,
          allowances: allowances,
          onUserChanged: handleUserChange.bind(this),
          onAllowancesChanged: handleAllowancesChange.bind(this)
        } as OutletContextAdminUser
      }
    ></Outlet>
  ) : (
    <div className="relative">
      <div className="flex justify-end gap-2">
        <GhostButton
          type="reset"
          disabled={!hasChanges.user && !hasChanges.allowances && !newUser}
          onClick={handleCancel.bind(this)}
        >
          Abbrechen
        </GhostButton>
        <Button
          color="accent"
          disabled={!hasChanges.user && !hasChanges.allowances}
          onClick={handleSave.bind(this)}
        >
          Speichern
        </Button>
      </div>
      <h1 className="mb-4 text-2xl font-semibold">
        {newUser ? (
          'Neuer BenuzerIn'
        ) : (
          <>
            {formatUsername(currentUser?.lastName, currentUser?.firstName)}
            <span className="ml-2 h-10 text-base font-normal text-black/60">
              [
              <span
                className="px-0.5"
                onClick={handleSelectUsername.bind(this)}
              >
                {currentUser?.username}
              </span>
              ]
            </span>
          </>
        )}
      </h1>
      <Tabs basePath={`/admin/users/${currentUser?.userId}`} tabs={tabs}>
        {currentUser && (
          <Outlet
            context={
              {
                newUser,
                selectedUser: currentUser,
                userId: props.selectedUser.userId,
                modules: props.modules,
                groups: props.groups,
                templates: props.templates,
                activeUsers: props.activeUsers,
                lockedUsers: props.activeUsers,
                emailState: props.emailState,
                allowances: allowances,
                onUserChanged: handleUserChange.bind(this),
                onAllowancesChanged: handleAllowancesChange.bind(this)
              } as OutletContextAdminUser
            }
          ></Outlet>
        )}
      </Tabs>
    </div>
  );
};
