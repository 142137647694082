import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Dialog, DialogContext, SplitButton } from '@kidsmanager/ui-core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { expandDateSet } from '../../helpers/dates';
import { downloadCsv } from '../../helpers/export-csv';
import { ReportTitle } from '../../components/report-title';
import { ReportLoader } from '../../components/report-loader';
import {
  compileReportData,
  initialiseSettings,
  InternalNonWorkMap,
  NonWorkByCostCenter,
  packSettings,
  sources,
  toCsvCell
} from './non-work-helpers';
import { NonWorkSettings } from './non-work-settings';
import {
  IExportColumn,
  IRosterTemplate,
  ITimesheetData,
  ITimesheetNonWork
} from '@kidsmanager/util-models';

export const NonWork = () => {
  const { t, i18n } = useTranslation('reports');
  const dialog = useContext(DialogContext);
  const client = useContext(ClientBackendContext);
  const [params] = useSearchParams();
  const [rawData, setRawData] = useState<ITimesheetData<ITimesheetNonWork>>();
  const [data, setData] = useState<NonWorkByCostCenter[]>();
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);
  const [exportColumns, setExportColumns] = useState<IExportColumn[]>([]);
  const [nonWorkMap, setNonWorkMap] = useState<InternalNonWorkMap[]>([]);

  const dates = useMemo(() => {
    return expandDateSet(new Date(), params.get('month'));
  }, [params]);

  useEffect(() => {
    Promise.all([
      client.admin.roster.templates.find(),
      client.report.settings('timesheet-non-work')
    ]).then(([templates, settings]) => {
      const init = initialiseSettings(templates, settings, t);
      setTemplates(init.templates);
      setExportColumns(init.columns);
      setNonWorkMap(init.map);
    });
  }, [client, dates, t]);

  useEffect(() => {
    client.report.timesheet.nonWork(dates.current).then((data) => {
      setData(compileReportData(data, nonWorkMap));
      setRawData(data);
    });
  }, [client, dates, nonWorkMap]);

  const toDisplayDate = (date: string): string => {
    const [year, month, day] = date.split('-').map(Number);
    const d = new Date(year, month - 1, day);
    return d.toLocaleString(i18n.language, { month: 'short', day: 'numeric' });
  };

  const handleSaveSettings = async (settings: {
    columns: IExportColumn[];
    map: InternalNonWorkMap[];
  }) => {
    const value = packSettings(settings.columns, settings.map);
    await client.report.saveSettings('timesheet-non-work', value);
    const updated = initialiseSettings(templates, value, t);
    setExportColumns(updated.columns);
    setNonWorkMap(updated.map);
  };

  const handleExport = (key: string | undefined) => {
    switch (key) {
      case 'zip':
        console.log('export zip');
        break;
      case 'settings':
        dialog.open(
          <Dialog
            title={t('timesheet.non-work.settings_title')}
            width="w-[750px]"
            onClose={() => dialog.close()}
          >
            <NonWorkSettings
              templates={templates}
              sources={sources}
              exportColumns={exportColumns}
              map={nonWorkMap}
              onCancel={() => dialog.close()}
              onSave={handleSaveSettings.bind(this)}
            />
          </Dialog>
        );
        break;
      default:
        downloadCsv(
          client.auth.tenant() || '',
          'nlz',
          dates.current,
          nonWorkMap,
          exportColumns,
          rawData,
          toCsvCell
        );
        break;
    }
  };

  return (
    <section className="p-4">
      <ReportTitle
        name={t('timesheet.non-work.title')}
        current={dates.current}
        prevUrl={`/report/timesheet/non-work?month=${dates.isoPrev}`}
        nextUrl={`/report/timesheet/non-work?month=${dates.isoNext}`}
        isPrior={dates.isPrior}
      >
        <SplitButton
          options={[
            { key: 'all', label: t('common.export_all') },
            { key: 'zip', label: t('common.export_zip') },
            {
              key: 'settings',
              label: t('common.settings'),
              disabled: !client.auth.inRole('admin')
            }
          ]}
          onClick={handleExport.bind(this)}
        >
          <span className="px-2">{t('common.export')}</span>
        </SplitButton>
      </ReportTitle>
      <ReportLoader loading={!data} noData={data?.length === 0}>
        {data &&
          data.map((group, i) => (
            <table key={i} className="mb-6 w-full max-w-2xl">
              <thead>
                <tr>
                  <th className="pb-2 text-left">{`Kostenstelle ${group.costcenter}`}</th>
                  <th className="pb-2 text-left">Beschreibung</th>
                  <th className="pb-2 text-left">Buchungscode</th>
                  <th className="pb-2 text-left">Von</th>
                  <th className="pb-2 text-left">Bis</th>
                  <th className="pb-2 text-left">Tage</th>
                </tr>
              </thead>
              <tbody>
                {group.values.map((item, index) => (
                  <tr key={index} className="text-sm">
                    <td className="pl-6">{item.name}</td>
                    <td className="opacity-50">{item.label}</td>
                    <td className="pr-4">{item.code}</td>
                    <td className="pr-4">{toDisplayDate(item.from)}</td>
                    <td className="pr-4">{toDisplayDate(item.to)}</td>
                    <td className="opacity-50">{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
      </ReportLoader>
    </section>
  );
};
