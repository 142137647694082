import {
  ICarryover,
  IMembership,
  IMembershipBase,
  IRosterScheduled,
  ITeamRosterConfig
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterTeam {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async config(group: string): Promise<ITeamRosterConfig> {
    return cache.get(`team-${group}-config`, async () => {
      return (await this.fetch(`api/roster/team/config?group=${group}`)).json();
    });
  }

  async updateConfig(
    group: string,
    config: ITeamRosterConfig
  ): Promise<ITeamRosterConfig> {
    cache.clear(`team-${group}-config`);
    await this.fetch(`api/roster/team/config?group=${group}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(config)
    });
    return config;
  }

  async list(
    groupId: string,
    year: number,
    month: number
  ): Promise<IMembership[]> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`team-${groupId}-${iso_month}`, async () => {
      return (
        await this.fetch(`api/roster/team?month=${iso_month}&group=${groupId}`)
      ).json();
    });
  }

  async carryover(
    year: number,
    month: number,
    groupId: string
  ): Promise<ICarryover[]> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`team-${groupId}-${iso_month}-carryover`, async () => {
      return (
        await this.fetch(
          `api/roster/team/carryover?month=${iso_month}&group=${groupId}`
        )
      ).json();
    });
  }

  async member(
    groupId: string,
    memberId: string,
    month: string
  ): Promise<IMembership> {
    console.log(groupId, memberId, month);
    return cache.get(`team-member-${memberId}`, async () => {
      return (
        await this.fetch(
          `api/roster/team/member?month=${month}&group=${groupId}&id=${memberId}`
        )
      ).json();
    });
  }

  async update(
    groupId: string,
    year: number,
    month: number,
    team: IMembershipBase[]
  ): Promise<IMembership[]> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    const key = `team-${groupId}-${iso_month}`;
    cache.clear(key);
    return cache.get(key, async () => {
      return (
        await this.fetch(
          `api/roster/team?month=${iso_month}&group=${groupId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ team })
          }
        )
      ).json();
    });
  }

  async updateScheduled(
    groupId: string,
    year: number,
    month: number,
    scheduled: IRosterScheduled[]
  ): Promise<void> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`team-${groupId}-${iso_month}`);
    await this.fetch(
      `api/roster/team/scheduled?month=${iso_month}&group=${groupId}`,
      {
        method: 'PUT',
        body: JSON.stringify({ team: scheduled })
      }
    );
  }
}
