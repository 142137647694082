import { Input } from '@kidsmanager/ui-core';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ShiftPatterns } from './components/editor-shift-patterns';
import { SaveAs } from './components/editor-save-as';
import { ContextAdminRosterOutlet } from './context-admin-roster-outlet';
import {
  IRosterTemplateSaveAs,
  IRosterTemplateShift
} from '@kidsmanager/util-models';
import { useTranslation } from 'react-i18next';

export const AdminRosterGeneral = () => {
  const context = useOutletContext<ContextAdminRosterOutlet>();
  const { template } = context;
  const [name, setName] = useState('');
  const { t, ready } = useTranslation('admin-roster');

  useEffect(() => {
    if (!template) {
      return;
    }
    setName(template.name);
  }, [template]);

  const handleRename = (value: string) => {
    setName(value);
    template.name = value;
    context.onChange?.();
  };

  const handlePatternChange = (patterns: IRosterTemplateShift[]) => {
    template.shiftPatterns = patterns;
    context.onChange?.();
  };

  const handleSaveAsChange = (options: IRosterTemplateSaveAs[]) => {
    template.saveAs = options;
    context.onChange?.();
  };

  return (
    template &&
    ready && (
      <div key={template.id} className="flex flex-col gap-4">
        <Input
          name="roster-template-name"
          autocomplete="off"
          label={t('roster.name')}
          value={name}
          onChange={handleRename.bind(this)}
        />
        <ShiftPatterns
          label={t('roster.shift_patterns')}
          patterns={template.shiftPatterns}
          onChange={handlePatternChange.bind(this)}
        />
        <SaveAs
          label={t('roster.save_as')}
          options={template.saveAs}
          onChange={handleSaveAsChange.bind(this)}
        />
      </div>
    )
  );
};
