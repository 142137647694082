import {
  ITimesheetData,
  ITimesheetNonWork,
  ITimesheetPremium
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiReportTimesheet {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async premiums(
    month: Date,
    option?: { force: boolean }
  ): Promise<ITimesheetData<ITimesheetPremium>> {
    const monthStr = month.toLocaleISOMonth();
    const key = `premiums-${monthStr}`;
    if (option?.force) {
      console.log('cleaing cache');
      cache.clear(key);
    }
    return cache.get(key, async () =>
      (
        await this.fetch(`/api/report/timesheet/premiums?month=${monthStr}`)
      ).json()
    );
  }
  async nonWork(month: Date): Promise<ITimesheetData<ITimesheetNonWork>> {
    const monthStr = month.toLocaleISOMonth();
    return cache.get(`non-work-${monthStr}`, async () =>
      (
        await this.fetch(`/api/report/timesheet/non-work?month=${monthStr}`)
      ).json()
    );
  }
}
