import { IAbsence, IUserAbsences } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiTimesheetAbsences {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async forUsers(
    year: number,
    month: number,
    users: { id: string }[]
  ): Promise<IUserAbsences[]> {
    if (!users.length) {
      return [];
    }
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IUserAbsences[]>(
      `absences-${iso_month}-${users.join('-')}`,
      async () => {
        const response = await this.fetch(
          `/api/timesheet/absence?type=sick&month=${iso_month}&logged=before${users.map(({ id }) => `&id=${id}`).join('')}`
        );
        return await response.json();
      }
    );
  }

  async find(userId?: string): Promise<IAbsence[]> {
    return cache.get(`absences-${userId}`, async () =>
      (await this.fetch(`/api/timesheet/${userId}/absence?type=sick`)).json()
    );
  }

  async findForMonth(
    year: number,
    month: number,
    userId?: string
  ): Promise<IAbsence[]> {
    return cache.get(`absences-${userId}-${year}-${month}`, async () =>
      (
        await this.fetch(
          `/api/timesheet/${userId}/absence?year=${year}&month=${month}&type=sick`
        )
      ).json()
    );
  }

  async findForWeek(date: Date, userId?: string): Promise<IAbsence[]> {
    const { year, num: week } = date.getWeek();

    return cache.get(`absences-${userId}-week-${year}-${week}`, async () =>
      (
        await this.fetch(
          `/api/timesheet/${userId}/absence?year=${year}&week=${week}&type=sick`
        )
      ).json()
    );
  }

  async register(value: IAbsence, userId: string) {
    cache.clear(`absences-${userId}*`);
    return this.fetch(`/api/timesheet/${userId}/absence`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(value)
    });
  }

  async update(absence: IAbsence, userId?: string) {
    userId = userId || 'my';
    cache.clear(`absences-${userId}*`);
    return this.fetch(
      `/api/timesheet/${userId}/absence/${absence.from}?type=${absence.type}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(absence)
      }
    );
  }

  async delete(
    type: 'sick' | 'other',
    from: string,
    userId: string | undefined
  ) {
    cache.clear(`absences-${userId}*`);
    return this.fetch(`/api/timesheet/${userId}/absence/${from}?type=${type}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async note(
    from: string,
    userId: string | undefined
  ): Promise<{ url: string; mimeType: string }> {
    userId = userId || 'my';
    return cache.get(`note-${userId}-${from}`, async () =>
      (await this.fetch(`/api/timesheet/${userId}/absence/${from}/note`)).json()
    );
  }

  async noteUploadUrl(
    fromIsoDate: string,
    userId: string
  ): Promise<{ url: string; blobName: string }> {
    return (
      await this.fetch(
        `/api/timesheet/${userId}/absence/${fromIsoDate}/note/upload-url`
      )
    ).json();
  }
}
