import { Dialog, DialogContext, SplitButton } from '@kidsmanager/ui-core';
import { PremiumsSettings } from './premiums-settings';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IExportColumn,
  IPremiumMap,
  IRosterTemplate
} from '@kidsmanager/util-models';
import {
  compileReportData,
  initialiseSettings,
  InternalPremiumMap,
  packSettings,
  PremiumsByCostCenter
} from './premiums-helpers';
import { expandDateSet } from '../../helpers/dates';
import { ReportTitle } from '../../components/report-title';
import { ReportLoader } from '../../components/report-loader';

const sources = [
  { key: 'month', label: 'Monat' },
  { key: 'year', label: 'Jahr' },
  { key: 'day', label: 'Tag' },
  { key: 'employeenumber', label: 'Mitarbeiternummer' },
  { key: 'amount', label: 'Menge' },
  { key: 'costcenter', label: 'Kostenstelle' },
  { key: 'code', label: 'Lohnart' }
];

export const Premiums = () => {
  const { t } = useTranslation('reports');
  const dialog = useContext(DialogContext);
  const client = useContext(ClientBackendContext);
  const [params] = useSearchParams();
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);
  const [exportColumns, setExportColumns] = useState<IExportColumn[]>([]);
  const [premiumsMap, setPremiumsMap] = useState<InternalPremiumMap[]>();
  const [data, setData] = useState<PremiumsByCostCenter[]>();

  const dates = useMemo(() => {
    return expandDateSet(new Date(), params.get('month'));
  }, [params]);

  useEffect(() => {
    Promise.all([
      client.admin.roster.templates.find(),
      client.report.settings('timesheet-premiums')
    ]).then(([templates, settings]) => {
      const init = initialiseSettings(templates, settings, t);
      setTemplates(init.templates);
      setExportColumns(init.columns);
      setPremiumsMap(init.map);
      client.report.timesheet.premiums(dates.current).then((data) => {
        setData(compileReportData(data, init.map, t));
      });
    });
  }, [client, dates, t]);

  const handleSettingsSave = async (settings: {
    columns: IExportColumn[];
    map: IPremiumMap[];
  }) => {
    const value = packSettings(settings.columns, settings.map);
    await client.report.saveSettings('timesheet-premiums', value);
    const updated = initialiseSettings(templates, value, t);
    setExportColumns(updated.columns);
    setPremiumsMap(updated.map);
    client.report.timesheet
      .premiums(dates.current, { force: true })
      .then((data) => {
        setData(compileReportData(data, updated.map, t));
      });
  };

  const handleExport = (key: string | undefined) => {
    if (!premiumsMap) return;

    switch (key) {
      case 'zip':
        console.log('Export zip');
        break;
      case 'settings':
        dialog.open(
          <Dialog
            title={t('timesheet.premiums.settings_title')}
            width="w-[750px]"
            onClose={() => dialog.close()}
          >
            <PremiumsSettings
              templates={templates}
              sources={sources}
              map={premiumsMap}
              exportColumns={exportColumns}
              onCancel={() => dialog.close()}
              onSave={handleSettingsSave.bind(this)}
            />
          </Dialog>
        );
        break;
      default:
        console.log('Export all');
        break;
    }
  };

  return (
    <section className="p-4">
      <ReportTitle
        name={t('timesheet.premiums.title')}
        current={dates.current}
        prevUrl={`/report/timesheet/premiums?month=${dates.isoPrev}`}
        nextUrl={`/report/timesheet/premiums?month=${dates.isoNext}`}
        isPrior={dates.isPrior}
      >
        <SplitButton
          options={[
            { key: 'all', label: t('common.export_all') },
            { key: 'zip', label: t('common.export_zip') },
            {
              key: 'settings',
              label: t('common.settings'),
              disabled: !client.auth.inRole('admin')
            }
          ]}
          onClick={handleExport.bind(this)}
        >
          <span className="px-2">{t('common.export')}</span>
        </SplitButton>
      </ReportTitle>
      <ReportLoader loading={!data} noData={data?.length === 0}>
        {data &&
          data.map((group) => (
            <table key={group.costcenter} className="mb-6 w-full max-w-2xl">
              <thead>
                <tr>
                  <th className="pb-2 text-left">{`Kostenstelle ${group.costcenter}`}</th>
                  <th className="pb-2 text-left">Beschreibung</th>
                  <th className="pb-2 text-left">Lohnart</th>
                  <th className="pb-2 text-left">Menge</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {group.values.map((item, index) => (
                  <tr key={index} className="text-sm">
                    <td className="pl-6">{item.name}</td>
                    <td className="opacity-50">{item.label}</td>
                    <td className="pr-4 text-right">{item.code}</td>
                    <td className="pr-4 text-right">{item.value}</td>
                    <td className="opacity-50">{item.unit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
      </ReportLoader>
    </section>
  );
};
