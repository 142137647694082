import { InternalAssignment } from './internal-assignment';
import { InternalTest } from './internal-test';

const Monday = 1;

export class WeekRestMask {
  private startOffset = 0;
  private endOffset = 0;

  weeks: boolean[][] = [];

  constructor(
    private afterNonRest: { h: number; m: number },
    private restDays = 2
  ) {}

  load(dates: Date[], plan: InternalAssignment[][]): WeekRestMask {
    if (dates.length === 0 || plan.length === 0) {
      return this;
    }

    const middleOfMonth = dates[Math.floor(dates.length / 2)];
    const year = middleOfMonth.getFullYear();
    const month = middleOfMonth.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const { h, m } = this.afterNonRest;

    this.weeks = [];
    let dayIndex = 0;
    for (const [i, day] of dates.entries()) {
      const isMonday = day.getDay() === Monday;
      if (!isMonday && this.weeks.length === 0) {
        continue;
      }
      if (isMonday && day > lastDayOfMonth) {
        this.endOffset = i;
        break; //finished with the month of interest
      }
      if (isMonday) {
        dayIndex = 0;
        this.startOffset = this.weeks.length === 0 ? i : this.startOffset;
        this.weeks.push(Array(7).fill(true));
      }
      if (plan[i].some((x) => x.readonly)) {
        dayIndex++;
        continue;
      }
      const union = InternalAssignment.union(...plan[i]);
      const thisDay = InternalAssignment.toTimestamp(m, h, i);
      const nextDay = InternalAssignment.toTimestamp(m, h, i + 1);
      if (union && union.end > thisDay) {
        this.weeks[this.weeks.length - 1][dayIndex] = false;
      }
      if (union && union.end > nextDay) {
        this.weeks[this.weeks.length - 1][dayIndex + 1] = false;
      }
      dayIndex++;
    }
    return this;
  }

  toTestMask(dayIndex: number, test: InternalTest): boolean[][] {
    const testMask: boolean[][] = Array.from(
      { length: this.weeks.length },
      () => Array(7).fill(true)
    );
    if (dayIndex < this.startOffset || dayIndex >= this.endOffset) {
      //not adding anything outside the month of interest
      return testMask;
    }
    const { h, m } = this.afterNonRest;
    const cutoff = InternalAssignment.toTimestamp(m, h, dayIndex);
    const nextDayCutOff = InternalAssignment.toTimestamp(m, h, dayIndex + 1);
    const testEnd = test.end(dayIndex);

    const offset = dayIndex - this.startOffset;
    if (testEnd > cutoff) {
      const weekIndex = Math.floor(offset / 7);
      const dayOfWeek = offset % 7;
      testMask[weekIndex][dayOfWeek] = false;
    }
    if (testEnd > nextDayCutOff) {
      const weekIndex = Math.floor((offset + 1) / 7);
      const dayOfWeek = (offset + 1) % 7;
      if (weekIndex < testMask.length) {
        testMask[weekIndex][dayOfWeek] = false;
      }
    }

    return testMask;
  }

  blockOn(dayIndex: number, test: boolean[][]): boolean {
    const offset = dayIndex - this.startOffset;
    const weekIndex = Math.floor(offset / 7);
    if (weekIndex < 0 || weekIndex >= test.length) {
      return false;
    }
    const combined =
      test[weekIndex]?.map((val, i) => val && this.weeks[weekIndex][i]) || [];
    const max = this.longestRestPeriod(combined);
    return max < this.restDays;
  }

  private longestRestPeriod(value: boolean[]): number {
    return value.reduce(
      (acc, val) => {
        if (val) {
          acc.current++;
          acc.max = Math.max(acc.max, acc.current);
        } else {
          acc.current = 0;
        }
        return acc;
      },
      { current: 0, max: 0 }
    ).max;
  }
}
