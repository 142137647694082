import {
  EntityEmailState,
  EntityUser,
  EntityUserAllowance,
  Role,
  tableKeys
} from '@kidsmanager/util-storage';

// TODO Richard: Review usage
export interface IUserGroup {
  name: string;
  users: IBasicUser[];
}

export interface IGoogleUser {
  email: string;
  lastName: string;
  firstName: string;
}

export interface IBasicUser {
  userId: string;
  firstName: string;
  lastName: string;
  employeeId: string;
}

export interface IFullUser {
  userId: string;
  username?: string;
  lastName?: string;
  firstName?: string;
  phone?: string;
  phonePrivate?: string;
  email?: string;
  verifiedEmail?: boolean;
  managerId?: string;
  primaryGroupId?: string;
  groupIds?: string[];
  roles?: IRole[];
  employeeId?: string;
  originalStartDate?: string;
  rosterPlanExclude: boolean;
  dutyRosters?: string;
  hoursPerWeek?: number;
  holidayAllowance?: number;
  inactive?: boolean;
  inactiveOn?: string;
}

export interface IUserAllowance {
  id: string;
  value: number;
  from: string;
  to?: string;
  reference?: string;
}

export type IEmailStateType = 'unverified' | 'verified' | 'pending' | 'error';

export interface IEmailState {
  email: string;
  state: IEmailStateType;
  added?: string; // ISO date
  verified?: string; // ISO date
  lastUsed?: string; // ISO date
  lastError?: string;
}

export type IRole =
  | 'admin'
  | 'manager'
  | 'controller'
  | 'leader'
  | 'user'
  | 'guest';

export const ROLE_LABELS: Map<IRole, string> = new Map([
  ['admin', 'Administrator'],
  ['manager', 'ManagerIn'],
  ['controller', 'Buchhaltung'],
  ['leader', 'TeamleiterIn'],
  ['user', 'BetreuerIn'],
  ['guest', 'Gast']
]);

export const mapUserFromEntity = (
  user: EntityUser,
  roles: Role[]
): IFullUser => {
  const userId = tableKeys.user.rk.extractId(user);
  return {
    userId,
    username: user.Username,
    lastName: user.LastName || '',
    firstName: user.FirstName || '',
    email: user.Email || '',
    verifiedEmail: !!user.VerifiedEmail,
    phone: user.Telephone || '',
    phonePrivate: user.PhonePrivate || '',
    roles: roles,
    primaryGroupId: user.PrimaryGroupId || '',
    groupIds: user.GroupList?.split(';') || [],
    managerId: user.ManagerId || '',
    employeeId: user.EmployeeId,
    originalStartDate: user.OriginalStartDate,
    dutyRosters: user.DutyRosters,
    rosterPlanExclude: user.RosterPlanExclude || false,
    hoursPerWeek: user.HoursPerWeek,
    holidayAllowance: user.HolidayAllowance,
    inactive: user.Inactive,
    inactiveOn: user.InactiveOn
  };
};

export const mapUserToEntity = (
  tenant: string,
  user: IFullUser
): EntityUser => {
  return {
    partitionKey: tableKeys.user.pk(tenant),
    rowKey: tableKeys.user.rk.byUserId(user.userId),
    Username: user.username,
    FirstName: user.firstName,
    LastName: user.lastName,
    Email: user.email,
    VerifiedEmail: !!user.verifiedEmail,
    Telephone: user.phone,
    PhonePrivate: user.phonePrivate,
    PrimaryGroupId: user.primaryGroupId,
    GroupList: user.groupIds?.join(';'),
    ManagerId: user.managerId,
    EmployeeId: user.employeeId,
    OriginalStartDate: user.originalStartDate,
    DutyRosters: user.dutyRosters,
    RosterPlanExclude: user.rosterPlanExclude,
    HoursPerWeek: user.hoursPerWeek,
    HolidayAllowance: user.holidayAllowance,
    Inactive: !!user.inactive,
    InactiveOn: user.inactiveOn
  } as EntityUser;
};

export const mapUserAllowanceFromEntity = (
  allowance: EntityUserAllowance
): IUserAllowance => {
  return {
    id: tableKeys.user.allowances.extractId(allowance),
    value: allowance.Value,
    from: allowance.From,
    to: allowance.To,
    reference: allowance.Reference
  };
};

export const mapUserAllowanceToEntity = (
  tenant: string,
  userId: string,
  type: 'hours' | 'holiday',
  allowance: IUserAllowance
): EntityUserAllowance => {
  return {
    partitionKey: tableKeys.user.allowances.pk(tenant),
    rowKey: tableKeys.user.allowances.rk.exact(userId, type, allowance.id),
    Type: type,
    From: allowance.from,
    To: allowance.to,
    Value: allowance.value,
    Reference: allowance.reference
  } as EntityUserAllowance;
};

export const mapEmailStateFromEntity = (
  value?: EntityEmailState
): IEmailState => {
  if (!value) {
    return {
      email: '',
      state: 'unverified'
    };
  } else {
    return {
      email: value.Email,
      state: value.State,
      added: value.Added,
      verified: value.Verified,
      lastUsed: value.LastUsed,
      lastError: value.LastError
    };
  }
};
