import { InternalNonWorkMap } from './non-work-helpers';
import { Input } from '@kidsmanager/ui-core';

export interface NonWorkSettingsMapProps {
  map: InternalNonWorkMap[];
  onChange?: (update: InternalNonWorkMap[]) => void;
}

export const NonWorkSettingsMap = (props: NonWorkSettingsMapProps) => {
  const handleCodeChange = (key: string, value: string) => {
    const item = props.map.find((x) => x.key === key);
    if (item) {
      item.code = value;
    }
    props.onChange?.([...props.map]);
  };

  const handleSubcodeChange = (key: string, value: string) => {
    const item = props.map.find((x) => x.key === key);
    if (item) {
      item.subcode = value;
    }
    props.onChange?.([...props.map]);
  };

  return (
    <table className="w-full">
      <thead>
        <tr className="text-xs">
          <th></th>
          <th className="w-32">Buchungscode</th>
          <th className="w-32">Untercode</th>
        </tr>
      </thead>
      <tbody>
        {props.map.map((item, i) => (
          <tr key={i}>
            <td className="px-2 leading-10">
              {item.label}
              {item.tag && <span className="ml-2 text-sm">[{item.tag}]</span>}
            </td>
            <td>
              <Input
                value={item.code}
                onChange={handleCodeChange.bind(this, item.key)}
              />
            </td>
            <td>
              <Input
                value={item.subcode}
                onChange={handleSubcodeChange.bind(this, item.key)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
