import { Progress } from '@kidsmanager/ui-core';
import { useTranslation } from 'react-i18next';

export interface ReportLoaderProps {
  loading: boolean;
  noData: boolean;
  children: React.ReactNode;
}

export const ReportLoader = (props: ReportLoaderProps) => {
  const { t } = useTranslation('reports');

  if (props.loading) {
    return (
      <div className="mx-auto max-w-lg pt-10 text-center text-sm">
        <Progress mode="indeterminate" />
        <p className="mt-2 opacity-50">{t('common.analysing')}</p>
      </div>
    );
  } else if (props.noData) {
    return <div className="p-4 text-black/60">{t('common.no_data')}</div>;
  } else {
    return props.children;
  }
};
