import { quickid } from '@kidsmanager/ui-api';
import { ColumnType, SettingsTableRange } from '@kidsmanager/ui-core';
import { prependToList } from '@kidsmanager/util-common';
import { IUserAllowance } from '@kidsmanager/util-models';
import { Link } from 'react-router-dom';

export interface UserAllowanceEditorProps {
  title: string;
  hint?: string;
  cols: ColumnType[];
  disableOverlapCheck?: boolean;
  values: IUserAllowance[];
  startDate?: string;
  default: number;
  url?: string;
  onChange?: (values: IUserAllowance[], overlaps: boolean) => void;
}

export const UserAllowanceEditor = (props: UserAllowanceEditorProps) => {
  const getFromDate = (): Date => {
    try {
      if (props.values.length === 0 && props.startDate) {
        if (props.startDate.includes('-')) {
          const [yyyy, mm, dd] = props.startDate.split('-');
          return new Date(Number(yyyy), Number(mm) - 1, Number(dd));
        } else {
          const [dd, mm, yyyy] = props.startDate.split('.');
          return new Date(Number(yyyy), Number(mm) - 1, Number(dd));
        }
      }
    } catch {
      // ignore
    }
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  };

  const handleAdd = () => {
    const updated = prependToList<IUserAllowance[]>(
      quickid('UA', 'low'),
      props.values,
      getFromDate()
    );
    updated[0].value =
      props.values.length > 0 ? props.values[0].value : props.default;
    props.onChange?.(updated, false);
  };

  const handleRemove = (id: string) => {
    props.onChange?.(
      props.values.filter((x) => x.id !== id),
      false
    );
  };
  const meta = props.url ? (
    <Link className="absolute right-0 top-0 underline" to={props.url}>
      <span className="material-icons" style={{ fontSize: '16px' }}>
        tune
      </span>
    </Link>
  ) : undefined;

  return (
    <div className="relative -mb-2">
      <SettingsTableRange
        title={props.title}
        hint={props.hint}
        meta={meta}
        cols={props.cols}
        disableOverlapCheck={props.disableOverlapCheck}
        values={props.values}
        onChange={props.onChange}
        onAdd={handleAdd.bind(this)}
        onRemove={handleRemove.bind(this)}
      />
    </div>
  );
};
