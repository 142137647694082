import { Checkbox, Input, Option, Select } from '@kidsmanager/ui-core';
import { IFullUser, IUserAllowance } from '@kidsmanager/util-models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UserAllowanceEditor } from './components/user-allowance-editor';
import { OutletContextAdminUser } from './outlet-context-admin-user';

export const AdminUserShifts = () => {
  const ref = useRef<HTMLFormElement>(null);
  const startOfYear = useMemo(() => {
    const jan1st = new Date(new Date().getFullYear(), 0, 1);
    return jan1st.toLocaleISODate();
  }, []);
  const [startDate, setStartDate] = useState<string>();
  const [holidayHint, setHolidayHint] = useState<string>();
  const [hoursHint, setHoursHint] = useState<string>();

  const context = useOutletContext<OutletContextAdminUser>();
  const { selectedUser: user, allowances } = context;

  useEffect(() => {
    const handleReset = (event: unknown) => {
      ref.current?.reset();
    };
    document.addEventListener('adminUserAction', handleReset);
    return () => window.removeEventListener('adminUserAction', handleReset);
  }, []);

  useEffect(() => {
    setStartDate(user.originalStartDate);
  }, [user.originalStartDate]);

  const allEndsInNext3Months = (values: IUserAllowance[]) => {
    const nextQuarter = new Date();
    nextQuarter.setMonth(nextQuarter.getMonth() + 3);
    return (
      values.filter((x) => x.to && new Date(x.to) > nextQuarter).length === 0
    );
  };

  const handleOnFormChange = () => {
    if (!ref.current) {
      return;
    }
    const data = new FormData(ref.current);
    setStartDate(data.get('originalStartDate')?.toString());
    const partialUser: Partial<IFullUser> = {
      employeeId: data.get('employeeId')?.toString(),
      originalStartDate: data.get('originalStartDate')?.toString(),
      dutyRosters: data.get('dutyRosters')?.toString(),
      rosterPlanExclude: data.get('rosterPlanExclude') === 'on' ? true : false
    };
    if (partialUser) {
      context.onUserChanged(partialUser, false);
    }
  };

  const handleHoursChange = (values: IUserAllowance[], overlaps: boolean) => {
    setHoursHint(
      allEndsInNext3Months(values)
        ? undefined
        : 'Ein Bis-Datum wird nur benötigt, wenn der Anspruch geändert wird.'
    );
    context.onAllowancesChanged({ hours: values, hoursInvalid: overlaps });
  };

  const handleHolidayChange = (values: IUserAllowance[], overlaps: boolean) => {
    setHolidayHint(
      allEndsInNext3Months(values)
        ? undefined
        : 'Ein Bis-Datum wird nur benötigt, wenn der Anspruch geändert wird.'
    );
    context.onAllowancesChanged({
      holidays: values,
      hoursInvalid: overlaps
    });
  };

  return (
    <div className="flex max-w-md flex-col gap-4">
      <form
        ref={ref}
        key={`${user.userId}-${context.templates.length}`}
        autoComplete="off"
        className="flex flex-col gap-3"
        onChange={handleOnFormChange.bind(this)}
        onBlur={handleOnFormChange.bind(this)}
      >
        <Input
          name="employeeId"
          label="Dienstnummer"
          defaultValue={user.employeeId}
        />
        <Input
          name="originalStartDate"
          label="erstes Einstellungsdatum"
          placeholder="TT.MM.JJJJ"
          mask="date"
          defaultValue={user.originalStartDate}
        />
        <Select
          name="dutyRosters"
          label="Dienstvorlage"
          defaultValue={user.dutyRosters}
        >
          <Option value="">-- Keine Dienstvorlage --</Option>
          {context.templates?.map((template) => (
            <Option key={template.id} value={template.id}>
              {template.name}
            </Option>
          ))}
        </Select>
        <div className="pt-2">
          <Checkbox
            name="rosterPlanExclude"
            defaultChecked={!!user.rosterPlanExclude}
          >
            Von Dienstplanung ausnehmen
          </Checkbox>
        </div>
      </form>
      {allowances && (
        <>
          <UserAllowanceEditor
            title="Jährlicher Urlaubsanspruch"
            url={`/report/timesheet/adjustment?type=holidays&id=${user.userId}`}
            cols={[{ key: 'value', type: 'number', label: 'Tage' }]}
            values={allowances.holidays}
            startDate={startOfYear}
            default={25}
            hint={holidayHint}
            onChange={handleHolidayChange.bind(this)}
          />
          <UserAllowanceEditor
            title="Wochenstunden"
            url={`/report/timesheet/adjustment?type=hours&id=${user.userId}`}
            cols={[
              { key: 'value', type: 'number', label: 'Stunden' },
              { key: 'reference', type: 'string', label: 'Kostenstelle' }
            ]}
            values={allowances.hours}
            disableOverlapCheck={true}
            startDate={startDate}
            default={38}
            hint={hoursHint}
            onChange={handleHoursChange.bind(this)}
          />
        </>
      )}
    </div>
  );
};
