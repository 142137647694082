import { useId } from 'react';

export interface CheckboxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  children?: React.ReactNode;
  onChange?: (checked: boolean) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const id = useId();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.disabled) {
      props.onChange?.(!props.checked);
    }
  };

  const input = (
    <input
      id={props.id || id}
      name={props.name}
      disabled={props.disabled}
      type="checkbox"
      className="border-silver-400 checked:bg-checkmark checked:animate-checkmark outline-focus h-5 w-5 cursor-pointer appearance-none rounded-md border bg-no-repeat outline-offset-4"
      defaultChecked={props.defaultChecked}
      checked={props.checked}
      onChange={handleChange.bind(this)}
      onClick={props.onClick?.bind(this)}
    />
  );

  return props.children ? (
    <label
      className="inline-flex cursor-pointer items-start gap-2 leading-8"
      htmlFor={id}
    >
      {input}
      <span className="flex-1 leading-5">{props.children}</span>
    </label>
  ) : (
    input
  );
};
