import { useTranslation } from 'react-i18next';
import { ExportColumnEditor } from './column-editor';
import { useEffect, useState } from 'react';
import { LinkButton } from '@kidsmanager/ui-core';
import { quickid } from '@kidsmanager/ui-api';
import { IExportColumn } from '@kidsmanager/util-models';
import { DraggableRow } from './draggable-row';

export interface ExportColumnsEditorProps {
  sources: { key: string; label: string }[];
  columns: IExportColumn[];
  onChange?: (value: IExportColumn[]) => void;
}

export const ExportColumnsEditor = (props: ExportColumnsEditorProps) => {
  const { t } = useTranslation('reports');
  const [columns, setColumns] = useState<IExportColumn[]>([]);
  const [sources, setSources] = useState<{ key: string; label: string }[]>([]);

  useEffect(() => {
    setColumns(props.columns);
    setSources(props.sources);
  }, [props]);

  const handleAddColumn = () => {
    setColumns((prev) => {
      const next = [
        ...prev,
        { id: quickid(''), label: '', type: '', value: '' }
      ];
      props.onChange?.(next);
      return next;
    });
  };

  const handleDelete = ({ id }: IExportColumn) => {
    setColumns((prev) => {
      const next = prev.filter((x) => x.id !== id);
      props.onChange?.(next);
      return next;
    });
  };

  const handleChange = (
    id: string,
    type: string,
    label: string,
    value?: string
  ) => {
    setColumns((prev) => {
      const next = prev.map((column) =>
        id === column.id ? { id, label, type, value } : column
      );
      props.onChange?.(next);
      return next;
    });
  };

  const handleChangeOrder = (dragId: string, dropId: string) => {
    setColumns((prev) => {
      const dragIndex = prev.findIndex((x) => x.id === dragId);
      const dropIndex = prev.findIndex((x) => x.id === dropId);
      const newOrder = [...prev];
      newOrder.splice(dragIndex, 1);
      newOrder.splice(dropIndex, 0, prev[dragIndex]);
      props.onChange?.(newOrder);
      return newOrder;
    });
  };

  return (
    <div>
      <div className="mx-auto mt-4 max-w-md">
        {columns.map((column) => (
          <DraggableRow
            key={column.id}
            id={column.id}
            onChange={handleChangeOrder.bind(this)}
          >
            <ExportColumnEditor
              type={column.type}
              label={column.label}
              value={column.value}
              data={sources}
              onDelete={handleDelete.bind(this, column)}
              onChange={handleChange.bind(this, column.id)}
            />
          </DraggableRow>
        ))}

        <div className="mt-2">
          <LinkButton onClick={handleAddColumn.bind(this)}>
            + {t('common.add_column')}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};
