import { IconButton } from '@kidsmanager/ui-core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface ReportTitleProps {
  name: string;
  current: Date;
  prevUrl: string;
  nextUrl: string;
  isPrior: boolean;
  children?: React.ReactNode;
}

export const ReportTitle = (props: ReportTitleProps) => {
  const { i18n } = useTranslation();
  return (
    <div className="my-1 mb-2 flex">
      <h1 className="flex flex-1 items-center gap-2 text-xl">
        {props.name}
        <Link to={props.prevUrl}>
          <IconButton color="ghost">arrow_back</IconButton>
        </Link>
        {props.current.toLocaleString(i18n.language, {
          month: 'long',
          year: 'numeric'
        })}
        {props.isPrior ? (
          <Link to={props.nextUrl}>
            <IconButton color="ghost">arrow_forward</IconButton>
          </Link>
        ) : (
          <IconButton disabled color="ghost">
            arrow_forward
          </IconButton>
        )}
      </h1>
      <div className="flex space-x-2">{props.children}</div>
    </div>
  );
};
