import { Outlet, Route } from 'react-router-dom';

import * as Account from '@kidsmanager/feature-account';
import * as Admin from '@kidsmanager/feature-admin';
import * as Report from '@kidsmanager/feature-report';
import { JournalDashboard } from '@kidsmanager/feature-journal';

import { TeamDashboard } from '@kidsmanager/feature-team';
import { AppGuard } from './app-guard';
import { PageNotFound } from './page-not-found';
import { Panel, lazy } from '@kidsmanager/ui-core';

const Roster = lazy(() => import('@kidsmanager/feature-roster'), 'Roster');

export const AppRoutes = (): React.ReactNode => {
  return (
    <>
      <Route path="/login" element={<Account.FeatureLogin brand="kidsmgr" linkWithLegacy />} />
      <Route path="/login/mfa" element={<Account.FeatureMfa />}>
        <Route path="select" element={<Account.FeatureMfaSelect linkWithLegacy />} />
        <Route path="email" element={<Account.FeatureMfaEmail linkWithLegacy />} />
        <Route path="authenticator" element={<Account.FeatureMfaAuthenticator linkWithLegacy />} />
      </Route>
      <Route path="/login/legacy" element={<Account.LegacyRedirect />} />
      <Route path="/logout" element={<Account.FeatureLogout unlinkWithLegacy />} />
      <Route path="/google-callback" element={<Account.FeatureGoogleCallback brand="kidsmgr" />} />
      <Route path="/verify-email" element={<Account.FeatureEmailConfirmed />} />
      <Route element={<AppGuard />}>
        <Route path="/" element={<JournalDashboard />} />
        <Route path="/settings/group/:group" element={<Account.FeatureActiveGroup />} />
        <Route path="/settings/credentials" element={<Account.FeatureCredentials />} />
        <Route path="/team/:group" element={<TeamDashboard />}>
          <Route path="/team/:group/calendar" element={<Panel message="Kalender - In Arbeit" />} />
          <Route path="/team/:group/notes" element={<Panel message="Notizen - In Arbeit" />} />
          <Route path="/team/:group/drive" element={<Panel message="Speicherplatz - In Arbeit" />} />
          <Route path="/team/:group/medication" element={<Panel message="Medikation - In Arbeit" />} />
          <Route path="/team/:group/roster/:month?" element={<Roster />} />
        </Route>
        <Route path="/report" element={<Report.ReportIndex />}>
          <Route path="timesheet" element={<Outlet />}>
            <Route path="adjustment" element={<Report.Timesheet.Adjustment />} />
            <Route path="premiums" element={<Report.Timesheet.Premiums />} />
            <Route path="non-work" element={<Report.Timesheet.NonWork />} />
          </Route>
        </Route>
      </Route>
      <Route path="/admin" element={<AppGuard requires="admin" />}>
        <Route path="users/:id?" element={<Admin.AdminUsers />}>
          <Route index path="profile" element={<Admin.AdminUserProfile />} />
          <Route path="permissions" element={<Admin.AdminUserPermissions />} />
          <Route path="shifts" element={<Admin.AdminUserShifts />} />
          <Route path="access" element={<Admin.AdminUserAccess />} />
          <Route path="link/:idp" element={<Admin.AdminUserLink />} />
        </Route>
        <Route path="roster/:id?" element={<Admin.AdminRosters />}>
          <Route path="" element={<Admin.AdminRosterGeneral />} />
          <Route path="rules" element={<Admin.AdminRosterRules />} />
          <Route path="non-work" element={<Admin.AdminRosterNonWork />} />
        </Route>
        <Route path="groups/:id?" element={<Admin.AdminGroups />}>
          <Route index path="" element={<Admin.AdminGroupGeneral />} />
          <Route path="features" element={<Admin.AdminGroupFeatures />} />
          <Route path="features/drive/settings" element={<Admin.AdminGroupFeaturesDrive />} />
          <Route path="features/medication/settings" element={<Admin.AdminGroupFeaturesMedication />} />
          <Route path="features/roster/settings" element={<Admin.AdminGroupFeaturesRoster />} />
          <Route path="templates" element={<Admin.AdminGroupTemplates />} />
          <Route path="addressbook" element={<Admin.AdminGroupAddressbook />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  );
};
