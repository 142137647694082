import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { FeatureCard } from './components/feature-card';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import {
  IconCalendar,
  IconCloud,
  IconMedication,
  IconNotes,
  IconRoster
} from './components/feature-icons';
import { hasModule, Modules } from '@kidsmanager/util-models';

enum FeatureOption {
  'calendar',
  'notes',
  'drive',
  'medication',
  'roster'
}
export const AdminGroupFeatures = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const { t } = useTranslation('admin');
  const i18nPrefix = 'groups.features';

  const { selected, modules } = context;

  const onChange = (option: FeatureOption, checked: boolean) => {
    if (selected) {
      switch (option) {
        case FeatureOption.calendar:
          selected.features.calendar = checked;
          break;
        case FeatureOption.notes:
          selected.features.notes = checked;
          break;
        case FeatureOption.drive:
          selected.features.drive = checked;
          break;
        case FeatureOption.medication:
          selected.features.medication = checked;
          break;
        case FeatureOption.roster:
          selected.features.roster = checked;
          break;
      }
      context.onChange?.();
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <FeatureCard
        icon={<IconCalendar />}
        disabled={!hasModule(modules, Modules.Calendar)}
        titleKey={`${i18nPrefix}.calendar.title`}
        descriptionKey={t(`${i18nPrefix}.calendar.description`)}
        checked={selected.features.calendar || false}
        onChange={onChange.bind(this, FeatureOption.calendar)}
      />
      <FeatureCard
        icon={<IconCloud />}
        titleKey={`${i18nPrefix}.cloud.title`}
        descriptionKey={t(`${i18nPrefix}.cloud.description`)}
        // settingsLink={'drive/settings'} TODO: add when possible to control team file structure
        checked={selected.features.drive || false}
        onChange={onChange.bind(this, FeatureOption.drive)}
      />
      <FeatureCard
        icon={<IconNotes />}
        disabled={!hasModule(modules, Modules.Notes)}
        titleKey={`${i18nPrefix}.notes.title`}
        descriptionKey={t(`${i18nPrefix}.notes.description`)}
        checked={selected.features.notes || false}
        onChange={onChange.bind(this, FeatureOption.notes)}
      />
      <FeatureCard
        icon={<IconMedication />}
        disabled={!hasModule(modules, Modules.Medication)}
        titleKey={`${i18nPrefix}.medication.title`}
        descriptionKey={t(`${i18nPrefix}.medication.description`)}
        settingsLink={'medication/settings'}
        checked={selected.features.medication || false}
        onChange={onChange.bind(this, FeatureOption.medication)}
      />
      <FeatureCard
        icon={<IconRoster />}
        disabled={!hasModule(modules, Modules.DutyRoster)}
        titleKey={`${i18nPrefix}.roster.title`}
        descriptionKey={t(`${i18nPrefix}.roster.description`)}
        settingsLink={'roster/settings'}
        checked={selected.features.roster || false}
        onChange={onChange.bind(this, FeatureOption.roster)}
      />
    </div>
  );
};
