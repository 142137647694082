import {
  IDirectoryGroup,
  IDirectoryUser,
  MetaKeys
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiDirectoryUsers {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async by(id: string, meta?: MetaKeys[]): Promise<IDirectoryUser[]> {
    const metaParams = meta ? meta.map((m) => `&meta=${m}`).join('&') : '';
    return cache.get(`user-${id}`, async () => {
      return (
        await this.fetch(`api/directory/users?id=${id}${metaParams}`)
      ).json();
    });
  }

  async active(meta?: MetaKeys[]): Promise<IDirectoryUser[]> {
    const metaParams = meta ? meta.map((m) => `meta=${m}`).join('&') : '';
    return cache.get(`user-all`, async () => {
      return (
        await this.fetch(
          `api/directory/users${metaParams ? `?${metaParams}` : ''}`
        )
      ).json();
    });
  }

  async locked(
    userIds: string[],
    meta?: MetaKeys[]
  ): Promise<IDirectoryUser[]> {
    if (!userIds || userIds.length === 0) {
      return [];
    }
    const userParams = userIds.map((id) => `&id=${id}`).join('&');
    const metaParams = meta ? meta.map((m) => `&meta=${m}`).join('&') : '';
    return cache.get(`user-locked`, async () => {
      return (
        await this.fetch(
          `api/directory/users?locked=true${userParams}${metaParams}`
        )
      ).json();
    });
  }
}

export class ApiDirectory {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {
    this.users = new ApiDirectoryUsers(auth, fetch);
  }
  users: ApiDirectoryUsers;

  async groups(): Promise<IDirectoryGroup[]> {
    return cache.get(`group-all`, async () => {
      return (await this.fetch(`api/directory/groups`)).json();
    });
  }
}
