import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Button, Checkbox, Input, Progress } from '@kidsmanager/ui-core';
import { IGoogleUser } from '@kidsmanager/util-models';
import { useContext, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { OutletContextAdminUser } from './outlet-context-admin-user';

const createUserName = (user: IGoogleUser, existing: string[]) => {
  const concatWithCount = (base: string, count: number) =>
    count === 0 ? base : `${base}${count}`;
  const base = `${user.firstName.at(0) || 'x'}.${user.lastName}`.toLowerCase();
  let count = 0;
  while (existing.includes(concatWithCount(base, count))) {
    count++;
  }
  return concatWithCount(base, count);
};

export const AdminUserLink = () => {
  const [available, setAvailable] = useState<IGoogleUser[]>([]);
  const [pattern, setPattern] = useState('');
  const [selected, setSelected] = useState<IGoogleUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  const client = useContext(ClientBackendContext);
  const context = useOutletContext<OutletContextAdminUser>();
  const navigate = useNavigate();

  const handleUserChecked = (user: IGoogleUser, checked: boolean) => {
    setSelected((prev) =>
      checked ? [...prev, user] : prev.filter((g) => g.email !== user.email)
    );
  };

  const handleAddUsers = () => {
    selected.forEach((user) => {
      const locked = context.lockedUsers.find((u) => u.email === user.email);
      const existing = [
        ...context.activeUsers.map((x) => x.username || ''),
        ...context.lockedUsers.map((x) => x.username || '')
      ];
      if (locked) {
        locked.inactive = false;
        context.onUserChanged(locked, false);
      } else {
        context.onUserChanged(
          {
            userId: 'new',
            username: createUserName(user, existing),
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            verifiedEmail: true
          },
          false
        );
        navigate('/admin/users/new/profile');
      }
    });
  };

  const handleSearch = (value: string) => {
    setPattern(value);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    if (value.trim().length === 0) {
      setAvailable([]);
      setLoading(false);
      return;
    }
    setDebounceTimer(
      setTimeout(() => {
        const active = context.activeUsers.map((u) => u.email || '');
        client.admin.users.google(value).then((users) => {
          setAvailable(users.filter((u) => !active.includes(u.email)));
          setLoading(false);
        });
      }, 500)
    );
    setLoading(true);
  };

  return (
    <div className="relative">
      <h1 className="mb-4 pt-4 text-2xl font-semibold">
        Aus Google Workspace hinzufügen...
      </h1>
      <Link
        to="/admin/users"
        className="absolute right-0 top-0 -mt-4 text-4xl text-gray-600 hover:text-black"
        aria-label="Close"
      >
        &times;
      </Link>
      <div className="max-w-md">
        <Input
          onChange={handleSearch.bind(this)}
          mask="names"
          placeholder="Suche nach Vorname, Nachname oder E-Mail"
          value={pattern}
        />
        <div className="min-h-2">
          {loading && <Progress mode="indeterminate" />}
        </div>
        <div className="border-silver-200 max-h-96 min-h-44 overflow-y-auto rounded border bg-white p-4 pt-3">
          {available.length > 0 ? (
            available.map((user) => (
              <div className="py-1" key={user.email}>
                <Checkbox
                  checked={selected.includes(user)}
                  onChange={(checked) => handleUserChecked(user, checked)}
                >
                  {user.firstName} {user.lastName}{' '}
                  <span className="text-sm text-black/50">({user.email})</span>
                </Checkbox>
              </div>
            ))
          ) : (
            <span className="text-sm text-black/30">
              {loading && 'Loading...'}
            </span>
          )}
        </div>
        <div className="text-right text-xs text-black/50">
          {available.length} gefunden
        </div>
      </div>

      <div className="mt-4">
        <Button
          onClick={handleAddUsers.bind(this)}
          disabled={selected.length === 0}
        >
          hinzufügen
        </Button>
      </div>
    </div>
  );
};
