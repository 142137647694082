import { useContext, useEffect, useState } from 'react';
import {
  Link,
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { emptyAccessMap, IAccessMap } from '@kidsmanager/util-models';

const redirectToPrimaryFeature = (
  value: IAccessMap,
  navigate: NavigateFunction,
  group: string | undefined
) => {
  if (group === undefined) {
    console.warn('No group provided');
    return;
  }
  const firstFeature = value.team[0].href;
  if (firstFeature.startsWith(window.location.origin)) {
    const primaryArea = firstFeature.split('/').pop();
    navigate(['/team', group, primaryArea].join('/'));
  } else {
    //vNext Only
    window.location.href = firstFeature;
  }
};

export const TeamDashboard = () => {
  const client = useContext(ClientBackendContext);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [accessMap, setAccessMap] = useState<IAccessMap>(emptyAccessMap);
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client.settings.accessMap().then((value) => {
      const parts = location.pathname.split('/').filter((x) => !!x);
      if (parts.length === 2) {
        redirectToPrimaryFeature(value, navigate, params.group);
      } else {
        const supported = !!value.team.find((tab) =>
          tab.href.endsWith(parts[2])
        );
        if (supported) {
          setAccessMap(value);
          setSelected(
            value.team.findIndex((tab) =>
              window.location.href.startsWith(tab.href)
            )
          );
          setLoading(false);
        } else {
          redirectToPrimaryFeature(value, navigate, params.group);
        }
      }
    });
  }, [client, params, navigate, location]);

  return loading ? null : (
    <section>
      <div
        id="tab-hdr"
        className="ml-2 flex items-end gap-7 border-b border-black/30 pt-5 text-black/70"
      >
        <h2 className="inline text-xl font-bold">
          {accessMap.user.currentGroup?.name}
        </h2>
        <ul className="inline-flex items-end gap-5 px-1 text-[1.1em]">
          {accessMap.team.map((tab, index) => (
            <li
              key={index}
              className={`inline-block h-7 border-b-[6px] px-0.5 ${
                index === selected
                  ? 'border-b-secondary text-black/70'
                  : 'border-b-black/30 text-black/30'
              }`}
            >
              <Link
                className="outline-focus outline-offset-4"
                to={tab.href}
                onClick={() => setSelected(index)}
              >
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Outlet />
    </section>
  );
};
