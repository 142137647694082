import {
  IAccessMap,
  IProfile,
  ITenantSettings
} from '@kidsmanager/util-models';
import { IAuth, fetch } from './auth';
import { BackendCache } from './backend-cache';

const cache = new BackendCache();

export class ApiSettings {
  private activeGroup: string | undefined;

  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async clear() {
    cache.clearAll();
  }

  async accessMap(groupId?: string): Promise<IAccessMap> {
    if (groupId && this.activeGroup !== groupId) {
      cache.clear('access-map');
      this.activeGroup = groupId;
    }
    return cache.get<IAccessMap>('access-map', async () => {
      const url = groupId
        ? `api/settings/access-map?group=${groupId}`
        : 'api/settings/access-map';
      return (await this.fetch(url)).json();
    });
  }

  async tenant(): Promise<ITenantSettings> {
    return cache.get('tenant', async () => {
      return (await this.fetch('api/settings/tenant')).json();
    });
  }

  async profile(): Promise<IProfile> {
    return cache.get('profile', async () => {
      return (await this.fetch('api/settings/profile')).json();
    });
  }

  async profileUpdate(
    values: { key: string; value: string | boolean }[]
  ): Promise<void> {
    await this.fetch('api/settings/profile', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    });
    cache.clear('profile');
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<boolean> {
    const response = await this.fetch('api/settings/password', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ oldPassword, newPassword })
    });
    return !response.ok;
  }

  async registerMfa(secret: string, code: string): Promise<boolean> {
    const response = await this.fetch('api/settings/mfa', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ secret, code })
    });
    return response.ok;
  }

  async unregisterMfa() {
    await this.fetch('api/settings/mfa', {
      method: 'DELETE'
    });
  }
}
