import {
  IReleasedMemberPlan,
  IReleasedPlan,
  IRosterDraftSet,
  IRosterPlanSet
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterPlan {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async draft(
    year: number,
    month: number,
    groupId: string,
    members: { id: string }[]
  ): Promise<IRosterDraftSet> {
    if (!members.length) {
      return {
        prev: { plans: [] },
        current: { plans: [], notes: [] },
        next: { plans: [] }
      };
    }
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    const ids = members.map(({ id }) => id).join('-');
    return cache.get<IRosterDraftSet>(`plan-${iso_month}-${ids}`, async () => {
      return (
        await this.fetch(
          `api/roster/plan/draft?month=${iso_month}&group=${groupId}${members.map(({ id }) => `&id=${id}`).join('')}`
        )
      ).json();
    });
  }

  async save(
    year: number,
    month: number,
    groupId: string,
    draft: IRosterPlanSet
  ) {
    cache.clearAll();
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    const ids = draft.plans.map((plan) => plan.id).join('-');
    return cache.get<IRosterDraftSet>(`plan-${iso_month}-${ids}`, async () => {
      return (
        await this.fetch(`api/roster/plan/draft/${groupId}/${iso_month}`, {
          method: 'PUT',
          body: JSON.stringify(draft)
        })
      ).json();
    });
  }

  async version(
    year: number,
    month: number,
    groupId: string,
    version: string,
    members: { id: string }[]
  ): Promise<IRosterPlanSet> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    const ids = members.map(({ id }) => id).join('-');
    return cache.get<IRosterPlanSet>(
      `plan-${iso_month}-${version}-${ids}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/version/${groupId}/${version}?month=${iso_month}${members.map(({ id }) => `&id=${id}`).join('')}`
          )
        ).json();
      }
    );
  }

  /**
   * Returns the last planned duty roster, released before the from date
   * for the given user.  This is relevant for sick leave registration.
   * @param from
   * @param days
   * @param userId
   */
  before(from: Date, days: number, userId: string) {
    const iso_from = from.toLocaleISODate();
    return cache.get<unknown[]>(
      `before-${iso_from}-${days}-${userId}`,
      async () =>
        (
          await this.fetch(
            `api/roster/plan/member/before?from=${iso_from}&days=${days}&id=${userId}`
          )
        ).json()
    );
  }

  async releases(groupId: string, year: number, month: number) {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IReleasedPlan[]>(
      `releases-${iso_month}-${groupId}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/release?month=${iso_month}&group=${groupId}`
          )
        ).json();
      }
    );
  }

  async released(
    year: number,
    month: number,
    userId?: string
  ): Promise<IReleasedMemberPlan> {
    userId = userId || 'my';
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IReleasedMemberPlan>(
      `released-${iso_month}-${userId}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/member/release?month=${iso_month}&id=${userId}`
          )
        ).json();
      }
    );
  }

  async release(
    groupId: string,
    year: number,
    month: number,
    members: { id: string }[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    return (
      await this.fetch(
        `api/roster/plan/release?month=${iso_month}&group=${groupId}${members.map(({ id }) => `&id=${id}`).join('')}`,
        {
          method: 'POST'
        }
      )
    ).json();
  }

  async unpublish(
    groupId: string,
    year: number,
    month: number,
    version: string,
    members: { id: string }[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    return (
      await this.fetch(
        `api/roster/plan/release/${version}?month=${iso_month}&group=${groupId}${members.map(({ id }) => `&id=${id}`).join('')}`,
        {
          method: 'DELETE'
        }
      )
    ).json();
  }

  async discard(
    groupId: string,
    year: number,
    month: number,
    version: string,
    members: { id: string }[]
  ): Promise<void> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    cache.clear(`plan-${iso_month}-${members.map(({ id }) => id).join('-')}`);
    await this.fetch(
      `api/roster/plan/version/${groupId}/${version}?month=${iso_month}${members.map(({ id }) => `&id=${id}`).join('')}`,
      {
        method: 'DELETE'
      }
    );
  }
}
