import {
  Button,
  ButtonGroup,
  DialogContext,
  Info,
  ToggleButton
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminUser } from './outlet-context-admin-user';
import { DialogNewPassword } from './dialogs/dialog-new-password';
import { ClientBackendContext } from '@kidsmanager/ui-api';

export const AdminUserAccess = () => {
  const context = useOutletContext<OutletContextAdminUser>();
  const dialog = useContext(DialogContext);
  const client = useContext(ClientBackendContext);
  const { selectedUser } = context;
  const [inactive, setInactive] = useState(selectedUser.inactive);
  const [lockMessage, setLockMessage] = useState<string>();

  useEffect(() => {
    const isAdmin = selectedUser.roles?.includes('admin');
    setInactive(isAdmin ? false : selectedUser.inactive);
    setLockMessage(isAdmin ? 'Admins können nicht gesperrt werden' : undefined);
  }, [selectedUser]);

  const handleStatusChange = (inactive: boolean) => {
    const inactiveOn = inactive ? new Date().toISOString() : '';
    context.onUserChanged({ inactive, inactiveOn }, false);
    setInactive(inactive);
  };

  const handleNewPassword = () => {
    dialog.open(
      <DialogNewPassword
        user={selectedUser}
        onCancel={() => dialog.close()}
        onSave={(password) => {
          dialog.close();
          client.admin.users.resetPassword(selectedUser.userId, password);
        }}
      />
    );
  };

  return (
    <div className="mt-8 grid max-w-sm grid-cols-[120px_auto] items-center gap-y-4">
      <label className="text-sm">Status</label>
      <div className="flex items-center gap-1">
        <ButtonGroup>
          <ToggleButton
            active={!inactive}
            disabled={lockMessage !== undefined}
            onChange={handleStatusChange.bind(this, false)}
          >
            Aktiv
          </ToggleButton>
          <ToggleButton
            active={inactive}
            disabled={lockMessage !== undefined}
            onChange={handleStatusChange.bind(this, true)}
          >
            Gesperrt
          </ToggleButton>
        </ButtonGroup>
        {lockMessage && <Info position="center">{lockMessage}</Info>}
      </div>
      <label className="text-sm">Passwort</label>
      <Button onClick={handleNewPassword.bind(this)}>
        neues Passwort erstellen
      </Button>
    </div>
  );
};
