import { RuleBase, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { InternalTest } from '../core/internal-test';
import { InternalAssignment } from '../core/internal-assignment';
import { WeekHoursMask } from '../core/week-hours-mask';
import { FromTo } from '../core/to-from';

export class MaxHoursWeek extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    test: InternalTest,
    plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('max-hours-week') || test.ignoreHours) {
      return;
    }
    const standbyEnabled = this.specs.isEnabled('standby-required');
    const maxHours = this.specs.getNumber('max-hours-week', 'length');
    const from = this.specs.getTime('standby-required', 'from');
    const to = this.specs.getTime('standby-required', 'to');
    const onCall = standbyEnabled ? new FromTo(from, to) : new FromTo();
    const mask = new WeekHoursMask(maxHours, onCall).load(this.days, plan);

    for (const [day] of plan.entries()) {
      const testMask = mask.toTestMask(day, test);
      if (mask.blockOn(day, testMask)) {
        blocked[day].blocked = true;
        blocked[day].notes.push(
          `Arbeiten von über ${maxHours} Stunden pro Woche erfordert die Genehmigung der Leitung`
        );
      }
    }
  }
}
