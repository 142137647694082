import {
  IExportColumn,
  ITimesheetData,
  ITimesheetNonWork
} from '@kidsmanager/util-models';

export type toCsvCell = (
  key: string,
  item: ITimesheetNonWork,
  employee: { employeeId?: string } | undefined,
  date: Date,
  map: { key: string; code: string }[]
) => string;

export const downloadCsv = (
  tenant: string,
  title: string,
  date: Date,
  map: { key: string; code: string }[],
  columns: IExportColumn[],
  data: ITimesheetData<ITimesheetNonWork> | undefined,
  toCell: toCsvCell
) => {
  if (!data) {
    return;
  }

  const rows = [columns.map((col) => col.label).join(';')];
  for (const item of data.data) {
    const employee = data.employees.find((x) => x.userId === item.userId);
    const cells: string[] = [];
    for (const { type: key } of columns) {
      cells.push(toCell(key, item, employee, date, map));
    }
    rows.push(cells.join(';'));
  }
  const file = rows.join('\n');
  const blob = new Blob([file], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  // Create a temporary link element
  const monthStr = `${date.getFullYear()}_${String(date.getMonth() + 1).padStart(2, '0')}`;
  const a = document.createElement('a');
  a.href = url;
  a.download = `${tenant}_${monthStr}_${title}.csv`;
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
