import { useEffect, useState } from 'react';
import { SettingsDrawer } from '../../components/settings-drawer';
import { useTranslation } from 'react-i18next';
import { Button } from '@kidsmanager/ui-core';
import { ExportColumnsEditor } from '../../components/export-columns-editor';
import { NonWorkSettingsMap } from './non-work-settings-map';
import { IExportColumn, IRosterTemplate } from '@kidsmanager/util-models';
import { InternalNonWorkMap } from './non-work-helpers';

export interface NonWorkSettingsProps {
  templates: IRosterTemplate[];
  sources: { key: string; label: string }[];
  exportColumns: IExportColumn[];
  map: InternalNonWorkMap[];
  onCancel?: () => void;
  onSave?: (settings: {
    columns: IExportColumn[];
    map: InternalNonWorkMap[];
  }) => void;
}

export const NonWorkSettings = (props: NonWorkSettingsProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const { t } = useTranslation('reports');
  const [tab, setTab] = useState(0);
  const [columns, setColumns] = useState<IExportColumn[]>([]);
  const [nonWorkMap, setNonWorkMap] = useState<InternalNonWorkMap[]>();

  useEffect(() => {
    if (!props.map) {
      return;
    }
    setColumns(structuredClone(props.exportColumns));
    setNonWorkMap(structuredClone(props.map));
  }, [props.map, props.exportColumns]);

  const handleExportColumnsChange = (value: IExportColumn[]) => {
    setTimeout(() => {
      setColumns(value);
      setIsDirty(JSON.stringify(props.exportColumns) !== JSON.stringify(value));
    }, 0);
  };

  const handleMapChange = (update: InternalNonWorkMap[]) => {
    setTimeout(() => {
      setNonWorkMap(update);
      setIsDirty(true);
    }, 0);
  };

  const handleCancel = () => {
    if (isDirty) {
      setIsDirty(false);
      setColumns(structuredClone(props.exportColumns));
      setNonWorkMap(structuredClone(props.map));
      setTab((prev) => {
        setTimeout(setTab.bind(this, prev), 0);
        return -1;
      });
    } else {
      props.onCancel?.();
    }
  };

  const handleSave = () => {
    if (!nonWorkMap) return;
    setIsDirty(false);
    props.onSave?.({ columns, map: nonWorkMap });
    setIsDirty(false);
  };

  return (
    nonWorkMap && (
      <>
        <SettingsDrawer
          tabs={[
            { label: t('common.analysis') },
            { label: t('common.columns') }
          ]}
          onChange={setTab.bind(this)}
        >
          {tab === 0 && (
            <NonWorkSettingsMap
              map={props.map}
              onChange={handleMapChange.bind(this)}
            />
          )}
          {tab === 1 && (
            <ExportColumnsEditor
              sources={props.sources}
              columns={columns}
              onChange={handleExportColumnsChange.bind(this)}
            />
          )}
        </SettingsDrawer>
        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={handleCancel.bind(this)}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={!isDirty}
            color="accent"
            onClick={handleSave.bind(this)}
          >
            {t('common.save')}
          </Button>
        </div>
      </>
    )
  );
};
