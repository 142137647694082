import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  DialogConfirmUnsaved,
  DialogContext,
  IconButton,
  Input,
  Progress
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  AdjustmentMap,
  buildMatrix,
  calcTotals,
  expandAdjustments
} from './adjustment-helpers';
import { IDirectoryUser, MetaKeys } from '@kidsmanager/util-models';

type IEditableUser = IDirectoryUser & { isDirty: boolean };
const requiredMeta: MetaKeys[] = [
  'dutyRosters',
  'hoursPerWeek',
  'holidayAllowance'
];

export const Adjustment = () => {
  const { t } = useTranslation('reports');
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();
  const dialog = useContext(DialogContext);
  const [params] = useSearchParams({
    type: 'hours',
    year: `${new Date().getFullYear()}`
  });

  const thisYear = useMemo(() => new Date().getFullYear(), []);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IEditableUser[]>([]);
  const [hidden, setHidden] = useState<string[]>([]);
  const [adjustments, setAdjustments] = useState<AdjustmentMap>([]);
  const [totals, setTotals] = useState<number[]>([]);
  const [matrix, setMatrix] = useState<string[][]>([]);
  const [highlight, setHighlight] = useState<IEditableUser>();
  const [isDirty, setIsDirty] = useState(false);
  const [monthOffset, setMonthOffset] = useState(-5);
  const [years, setYears] = useState<number[]>([]);
  const [heading, setHeading] = useState<string>('');

  const tab = useMemo<'hours' | 'holidays'>(
    () => (params.get('type') === 'hours' ? 'hours' : 'holidays'),
    [params]
  );

  const months = useMemo(() => {
    const offset = tab === 'hours' ? monthOffset : 0;
    return Array.from(
      { length: 12 },
      (_, i) => new Date(years[1], i + offset, 1)
    );
  }, [years, monthOffset, tab]);

  // Keyboard navigation
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const inputs = Array.from(document.querySelectorAll('input'));
      const index = Array.from(inputs).findIndex(
        (input) => input === document.activeElement
      );
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        inputs.at(index - 12)?.focus();
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        inputs.at(index + 12)?.focus();
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  //highlight user on load
  useEffect(() => {
    const id = params.get('id');
    setTimeout(() => {
      if (!id) {
        return;
      }
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    }, 200);
    setHighlight(users.find((user) => user.id === id));
  }, [users, params]);

  // title configuration
  useEffect(() => {
    const current = Number(params.get('year')) || thisYear;
    client.settings.tenant().then((settings) => {
      const fiscalMonth = settings.fiscalYearStart.month;
      const afterFiscalStart = new Date().getMonth() >= fiscalMonth - 1;
      const yearOffset = afterFiscalStart ? 0 : 12;
      setMonthOffset(fiscalMonth - yearOffset - 1);
      if (fiscalMonth === 1) {
        setHeading(`${current}`);
      } else if (afterFiscalStart) {
        setHeading(`${current}/${current + 1}`);
      } else {
        setHeading(`${current - 1}/${current}`);
      }
      setYears([current - 1, current]);
    });
  }, [client, params, thisYear]);

  // load user and adjustments
  useEffect(() => {
    if (years.length === 0) {
      return;
    }
    setLoading(true);
    Promise.all([
      client.directory.users.active(requiredMeta),
      client.timesheet.adjustments.all(...years)
    ]).then(async ([allUsers, storedAdj]) => {
      const ofInterest = storedAdj.reduce((acc, adj) => {
        if (monthOffset === 0 && adj.year !== years[1]) {
          return acc;
        }
        if (!acc.includes(adj.userId)) {
          acc.push(adj.userId);
        }
        return acc;
      }, [] as string[]);

      const filtered = allUsers.filter(
        (user) => user.meta['dutyRosters'] || ofInterest.includes(user.id)
      );

      setHidden(
        allUsers
          .filter(
            (user) => !user.meta['dutyRosters'] && !ofInterest.includes(user.id)
          )
          .map((u) => `${u.lastname}, ${u.firstname}`)
      );

      const missingIds = ofInterest.filter(
        (id) => !allUsers.find((user) => user.id === id)
      );
      const missing = await client.directory.users.locked(
        missingIds,
        requiredMeta
      );
      filtered.push(...missing);
      filtered.sort((a, b) =>
        (a.lastname || '') > (b.lastname || '') ? 1 : -1
      );

      const expandedAdjustments = expandAdjustments(filtered, years, storedAdj);
      const newMatrix = buildMatrix(
        filtered,
        years,
        monthOffset,
        expandedAdjustments,
        tab
      );

      setUsers(
        filtered.map((user) => ({ ...user, isDirty: false }) as IEditableUser)
      );
      setAdjustments(expandedAdjustments);
      setMatrix(newMatrix);
      setTotals(calcTotals(filtered, newMatrix, tab));
      setLoading(false);
    });
  }, [client, tab, years, monthOffset]);

  const getAllowance = (user: IDirectoryUser, type: string) => {
    const value =
      type === 'hours'
        ? Number(user.meta['hoursPerWeek'])
        : Number(user.meta['holidayAllowance']);
    return value ? value : '-';
  };

  const setValue = (userIndex: number, month: number, value: string) => {
    const parsed = parseFloat(value);
    if (value && value !== '-' && isNaN(parsed)) {
      return;
    }
    if (value && parsed < -999) {
      value = '-999';
    }
    if (value && parsed > 999) {
      value = '999';
    }

    setMatrix((prev) => {
      const next = structuredClone(prev);
      next[userIndex][month] = value;
      users[userIndex].isDirty = true;
      setTotals(calcTotals(users, next, tab));
      setIsDirty(true);
      return next;
    });
  };

  const executeSwitchTab = (type: 'hours' | 'holidays') => {
    switch (type) {
      case 'hours':
        navigate(
          `/report/timesheet/adjustment?year=${years[1]}&type=hours${highlight ? `&id=${highlight?.id}` : ''}`
        );
        break;
      case 'holidays':
        navigate(
          `/report/timesheet/adjustment?year=${years[1]}&type=holidays${highlight ? `&id=${highlight?.id}` : ''}`
        );
        break;
    }
  };

  const handleSwitchTab = (type: 'hours' | 'holidays') => {
    if (isDirty) {
      dialog.open(
        <DialogConfirmUnsaved
          onCancel={() => dialog.close()}
          onConfirm={() => {
            dialog.close();
            handleCancel();
            executeSwitchTab(type);
          }}
        />
      );
    } else {
      executeSwitchTab(type);
    }
  };

  const handleUserClicked = (user: IEditableUser) => {
    const selected = highlight?.id === user.id;
    if (selected && isDirty) {
      dialog.open(
        <DialogConfirmUnsaved
          onCancel={() => dialog.close()}
          onConfirm={() => {
            dialog.close();
            navigate(`/admin/users/${user.id}/shifts`);
          }}
        />
      );
    } else if (selected) {
      navigate(`/admin/users/${user.id}/shifts`);
    } else {
      setHighlight(user);
    }
  };

  const handleSave = async () => {
    const changes: AdjustmentMap = {};
    years.forEach((year) => (changes[year] = []));

    for (const [userIndex, user] of users.entries()) {
      if (!user.isDirty) {
        continue;
      }
      if (tab === 'holidays') {
        const y = years[1];
        adjustments[y][userIndex].holidays = matrix[userIndex].map(
          (v) => parseFloat(v) || 0
        );
        changes[y].push(adjustments[y][userIndex]);
      } else if (tab === 'hours') {
        const yearToChange = new Set<number>();
        matrix[userIndex].forEach((value, i) => {
          const y = i + monthOffset < 0 ? years[0] : years[1];
          const m = (i + monthOffset + 12) % 12;
          adjustments[y][userIndex].hours[m] = parseFloat(value) || 0;
          yearToChange.add(y);
        });
        for (const y of yearToChange) {
          changes[y].push(adjustments[y][userIndex]);
        }
      }
    }
    await Promise.all(
      years.map((y) => client.timesheet.adjustments.save(y, changes[y]))
    );
    setIsDirty(false);
  };

  const handleCancel = () => {
    const newMatrix = buildMatrix(users, years, monthOffset, adjustments, tab);
    setMatrix(newMatrix);
    setTotals(calcTotals(users, newMatrix, tab));
    setIsDirty(false);
  };

  return years.length === 0 ? undefined : (
    <section className="p-4">
      <div className="flex">
        <h1 className="flex flex-1 items-center gap-2 text-xl">
          {t('timesheet.adjustment.title')}
          <Link
            to={`/report/timesheet/adjustment?year=${years[1] - 1}&type=${tab}${highlight ? `&id=${highlight?.id}` : ''}`}
          >
            <IconButton color="ghost">arrow_back</IconButton>
          </Link>
          {heading}
          {years[1] < thisYear ? (
            <Link
              to={`/report/timesheet/adjustment?year=${years[1] + 1}&type=${tab}${highlight ? `&id=${highlight?.id}` : ''}`}
            >
              <IconButton color="ghost">arrow_forward</IconButton>
            </Link>
          ) : (
            <IconButton disabled color="ghost">
              arrow_forward
            </IconButton>
          )}
        </h1>
        <div className="flex space-x-2">
          <Button disabled={!isDirty} onClick={handleCancel.bind(this)}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={!isDirty}
            color="accent"
            onClick={handleSave.bind(this)}
          >
            {t('common.save')}
          </Button>
        </div>
      </div>
      <div className="h-4">{loading && <Progress mode="indeterminate" />}</div>
      <div className="flex">
        <div className="min-w-28 pt-2">
          <div
            onClick={handleSwitchTab.bind(this, 'hours')}
            className={`block cursor-pointer rounded-l-md pl-2 leading-8 ${tab === 'hours' && 'bg-neutral-300 font-semibold'}`}
          >
            {t('timesheet.adjustment.hours')}
          </div>
          <div
            onClick={handleSwitchTab.bind(this, 'holidays')}
            className={`block cursor-pointer rounded-l-md pl-2 leading-8 ${tab === 'holidays' && 'bg-neutral-300 font-semibold'}`}
          >
            {t('timesheet.adjustment.holidays')}
          </div>
        </div>
        <div className="max-h-[80vh] min-h-[80vh] flex-1 overflow-y-scroll rounded-md border border-neutral-300 px-2">
          <table className="w-full max-w-5xl">
            <thead className="sticky top-0 z-10 bg-white/80 backdrop-blur-md">
              <tr>
                <th className="min-w-40 pt-4"></th>
                <th className="w-20 pt-4 text-left align-bottom">
                  <div>{t('timesheet.adjustment.allowance')}</div>
                  <div className="text-xs font-normal">
                    {t(`timesheet.adjustment.allowance_${tab}`)}
                  </div>
                </th>
                {months.map((month, i) => (
                  <th
                    className="min-w-10 bg-yellow-200 pt-4 align-bottom"
                    key={i}
                    style={{
                      background: month.isThisMonth() ? undefined : 'inherit'
                    }}
                  >
                    {i === 0 || month.getMonth() === 0 ? (
                      <div>'{month.getFullYear() - 2000}</div>
                    ) : undefined}
                    {month.toLocaleDateString('de-DE', { month: 'short' })}
                  </th>
                ))}
                <th className="pt-4 align-bottom">Gesamt</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, userIndex) => (
                <tr
                  key={`${user.id}-${tab}`}
                  id={user.id}
                  style={{
                    backgroundColor:
                      highlight?.id === user.id
                        ? 'rgba(0, 0, 0, 0.05)'
                        : 'inherit'
                  }}
                >
                  <td
                    onClick={handleUserClicked.bind(this, user)}
                    className="cursor-pointer"
                    style={{
                      textDecoration:
                        highlight?.id === user.id ? 'underline' : 'none'
                    }}
                  >
                    {user.lastname}, {user.firstname}
                  </td>
                  <td className="pr-2 text-right">{getAllowance(user, tab)}</td>
                  {months.map((_, i) => (
                    <td
                      className="p-1"
                      key={i}
                      style={{
                        fontSize: '0.875rem',
                        width: tab === 'hours' && i === 0 ? '4.5rem' : 'unset'
                      }}
                    >
                      <Input
                        mask="number"
                        value={matrix[userIndex][i] || ''}
                        onChange={setValue.bind(this, userIndex, i)}
                      />
                    </td>
                  ))}
                  <td className="pr-2 text-right">
                    {totals[userIndex]
                      ? Math.round(totals[userIndex] * 10) / 10
                      : '0'}
                  </td>
                </tr>
              ))}
              {hidden.length > 0 && (
                <tr>
                  <td
                    colSpan={100}
                    className="text-r pt-10 text-sm text-black/50"
                  >
                    <details>
                      <summary className="cursor-pointer">
                        Einige BenutzerInnen werden nicht angezeigt, da sie
                        keine Dienstvorlage konfiguriert haben
                      </summary>
                      <div className="flex flex-wrap gap-x-3 gap-y-1 px-4 py-1">
                        {hidden.map((user, i) => (
                          <span
                            key={i}
                            className="rounded-full bg-neutral-200 px-2 py-0.5"
                          >
                            {user}
                          </span>
                        ))}
                      </div>
                    </details>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};
