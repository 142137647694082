export const expandDateSet = (
  today: Date,
  monthStr?: string | null
): {
  today: Date;
  current: Date;
  prev: Date;
  next: Date;
  isoPrev: string;
  isoNext: string;
  isPrior: boolean;
} => {
  const [year, month] = monthStr
    ? monthStr.split('-').map(Number)
    : [today.getFullYear(), today.getMonth() + 1];

  const current = new Date(year, month - 1, 1);
  const endOfCurrent = new Date(year, month, 0);
  const prev = new Date(year, month - 2, 1);
  const next = new Date(year, month, 1);
  const isoPrev = prev.toLocaleISOMonth();
  const isoNext = next.toLocaleISOMonth();
  const isPrior = endOfCurrent < today;
  return { today, current, prev, next, isoPrev, isoNext, isPrior };
};
