import { useState } from 'react';
import { datadogWorkAround } from '../helper';
import { IconButton } from './icon-button';

export interface ContextMenuItem {
  key: string;
  label: string;
  parent?: string;
  icon?: string;
  disabled?: boolean;
  options?: { key: string; label: string }[];
}

export interface ContextMenuButtonProps {
  disabled?: boolean;
  options: ContextMenuItem[];
  onClick?: (value: string) => void;
  icon?: string;
  padding?: string;
  color?: 'ghost';
  position?: 'overButton' | 'above';
  style?: 'square' | 'round';
}

export const ContextMenuButton = (props: ContextMenuButtonProps) => {
  const [showNested, setShowNested] = useState<
    {
      key: string;
      label: string;
      disabled?: boolean;
    }[]
  >();

  const handleClick = (option: ContextMenuItem) => {
    if (option.disabled === true) {
      return;
    }
    if (option.options) {
      setShowNested(option.options);
      return;
    }
    if (props.onClick) {
      const key = option.parent ? `${option.parent}:${option.key}` : option.key;
      props.onClick(key);
    }
    (document.activeElement as HTMLElement)?.blur();
  };

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    datadogWorkAround(e);
    setShowNested(undefined);
  };

  const hasIcons = props.options.some((option) => option.icon);
  const getPosition = () => {
    switch (props.position) {
      case 'overButton':
        return {
          top: 0
        };
      case 'above':
        return {
          bottom: '2rem'
        };
      default:
        return {
          top: '2rem'
        };
    }
  };

  return (
    <div>
      <div
        tabIndex={0}
        className={`outline-focus relative inline-flex cursor-pointer items-center outline-offset-2 ${!props.disabled && 'group'} ${props.padding}`}
        onFocusCapture={datadogWorkAround}
        onBlurCapture={handleBlur.bind(this)}
      >
        <IconButton color={props.color} disabled={props.disabled}>
          {props.icon || 'more_vert'}
        </IconButton>
        <ul
          className="shadow-hdr border-silver-300 absolute right-0 z-50 hidden min-w-32 border bg-white py-2 text-left group-focus-within:block"
          style={getPosition()}
        >
          {props.options.map((option) => (
            <li
              key={option.key}
              className="relative flex min-w-14 items-center text-nowrap px-4 py-2 text-sm hover:bg-stone-200"
              style={{ opacity: option.disabled ? 0.5 : 1 }}
              onClick={handleClick.bind(this, option)}
            >
              {hasIcons && (
                <>
                  <span className="material-icons mr-2 w-8 text-base">
                    {option.icon}
                  </span>
                  <span>{option.label}</span>
                </>
              )}
              {!hasIcons && option.label}
              {option.options && (
                <>
                  <span className="material-icons -mr-2 ml-2">arrow_right</span>
                  {showNested && (
                    <div className="absolute right-full top-0 h-10 w-20 bg-white">
                      <ul className="shadow-hdr border-silver-300 absolute right-0 z-[60] min-w-32 border bg-white py-2 text-left">
                        {showNested.map((option) => (
                          <li
                            key={option.key}
                            className="flex min-w-14 items-center text-nowrap px-4 py-2 text-sm hover:bg-stone-200"
                            style={{ opacity: option.disabled ? 0.5 : 1 }}
                            onClick={handleClick.bind(this, option)}
                          >
                            {option.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
