import { RuleBase, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { InternalTest } from '../core/internal-test';
import { InternalAssignment } from '../core/internal-assignment';
import { Interval } from '../core/interval';

export class MaxHoursTogether extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    test: InternalTest,
    plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('max-hours-together') || test.ignoreHours) {
      return;
    }
    const maxHours = this.specs.getNumber('max-hours-together', 'length');
    const minRestMinutes = this.specs.getNumber('rest-between', 'hours') * 60;

    if (test.duration > maxHours) {
      blocked.forEach((b) => {
        b.blocked = true;
        b.notes.push(`Dienst über ${maxHours} Stunden`);
      });
      return;
    }
    for (const [day, entries] of plan.entries()) {
      for (const entry of entries) {
        if (entry.ignoreHours) {
          continue;
        }

        const e = entry.interval();
        const t = test.interval(day);
        const rest = Interval.restMinutes(e, t);
        const hours = Interval.totalHours(e, t);
        if (rest < minRestMinutes && hours > maxHours) {
          blocked[day].blocked = true;
          blocked[day].notes.push(this.buildMessage(hours, maxHours));
        }
        // Now check the next day if covers multiple days
        if (e.endsNextDay() && day < plan.length - 1) {
          const tNext = test.interval(day + 1);
          const restNext = Interval.restMinutes(e, tNext);
          const hoursNext = Interval.totalHours(e, tNext);

          if (restNext < minRestMinutes && hoursNext > maxHours) {
            blocked[day + 1].blocked = true;
            blocked[day + 1].notes.push(this.buildMessage(hoursNext, maxHours));
          }
        }
        if (t.endsNextDay() && day < plan.length - 1) {
          const tPrev = test.interval(day - 1);
          const restPrev = Interval.restMinutes(e, tPrev);
          const hoursPrev = Interval.totalHours(e, tPrev);
          if (restPrev < minRestMinutes && hoursPrev > maxHours) {
            blocked[day - 1].blocked = true;
            blocked[day - 1].notes.push(this.buildMessage(hoursPrev, maxHours));
          }
        }
      }
    }
  }

  buildMessage(hours: number, maxHours: number): string {
    return `Kombinierte Dienste von ${hours.toFixed(0)} Stunden, der das maximal erlaubte Limit von ${maxHours} Stunden überschreitet`;
  }
}
