import { quickid } from '@kidsmanager/ui-api';
import {
  IExportColumn,
  IPremiumMap,
  IReportSettings,
  IRosterTemplate,
  ITimesheetData,
  ITimesheetPremium
} from '@kidsmanager/util-models';

export interface InternalPremiumMap extends IPremiumMap {
  label: string;
  tag?: string;
}

export interface PremiumItem {
  name: string;
  label: string;
  value: number;
  unit: string;
  code: string;
}

export interface PremiumsByCostCenter {
  costcenter: string;
  values: PremiumItem[];
}

const generateSetting = (label: string, type: string, value?: string) => {
  return { id: quickid(''), label, type, value };
};

const getNameFromRule = (templates: IRosterTemplate[], ruleId: string) => {
  const rule = templates
    .flatMap((t) => t.rules.find((r) => r.id === ruleId))
    .filter((r) => r?.enabled)
    .at(0);
  if (!rule) {
    return undefined;
  }
  const value = rule.values.find((x) => x.key === 'name');
  return value?.value;
};

export const buildPremiumsMap = (
  templates: IRosterTemplate[],
  settings: IPremiumMap[]
) => {
  const nightshift =
    getNameFromRule(templates, 'nightshift-required') || 'Nachtdienst';
  const standby =
    getNameFromRule(templates, 'standby-required') || 'Bereitschaftsdienst';

  const items: InternalPremiumMap[] = [
    { key: 'hol-sun', label: 'Feiertag / Sonntag', count: 'days', code: '' },
    {
      key: 'hol-nightshift',
      label: `${nightshift} (Feiertag)`,
      count: 'hours',
      code: ''
    },
    { key: 'nightshift', label: nightshift, count: 'hours', code: '' },
    { key: 'standby', label: standby, count: 'hours', code: '' }
  ];

  const seenTags = new Set<string>();
  templates
    .flatMap((x) => x.saveAs)
    .filter((x) => !x.infoOnly && x.abbreviation)
    .forEach((x) => {
      if (!seenTags.has(x.abbreviation)) {
        items.push({
          key: x.abbreviation.toLowerCase(),
          label: x.name,
          count: 'hours',
          tag: x.abbreviation,
          code: ''
        });
        seenTags.add(x.abbreviation);
      }
    });

  if (settings) {
    for (const item of items) {
      const match = settings.find((x) => x.key === item.key);
      if (match) {
        item.count = match.count;
        item.code = match.code;
      }
    }
  }

  return items;
};

export const initialiseSettings = (
  templates: IRosterTemplate[],
  settings: IReportSettings,
  t: (key: string) => string
) => {
  const columns: IExportColumn[] =
    settings.exportColumns.length > 0
      ? settings.exportColumns
      : [
          generateSetting('monat', 'month'),
          generateSetting('mitarbeiternummer', 'employeenumber'),
          generateSetting('menge', 'amount'),
          generateSetting('kostenstelle', 'costcenter')
        ];

  const other = settings.other as { map: IPremiumMap[] };
  const map = buildPremiumsMap(templates, other.map);
  return { templates, columns, map };
};

export const packSettings = (
  exportColumns: IExportColumn[],
  premiumsMap: IPremiumMap[]
) => {
  const other = { map: [] as IPremiumMap[] };
  other.map = premiumsMap.map((value) => ({
    key: value.key,
    count: value.count,
    code: value.code
  }));
  return { exportColumns, other };
};

export const compileReportData = (
  value: ITimesheetData<ITimesheetPremium>,
  map: InternalPremiumMap[],
  t: (key: string) => string
): PremiumsByCostCenter[] => {
  const lookup = value.employees.reduce(
    (acc, curr) => {
      acc[curr.userId] = curr;
      return acc;
    },
    {} as { [key: string]: { name: string; employeeId?: string } }
  );

  const byCostcenter = value.data.reduce(
    (acc, curr) => {
      if (!acc[curr.costcenter]) {
        acc[curr.costcenter] = [];
      }
      acc[curr.costcenter].push(curr);
      return acc;
    },
    {} as { [key: string]: typeof value.data }
  );

  const reportData: PremiumsByCostCenter[] = [];

  for (const group in byCostcenter) {
    const groupValues: PremiumsByCostCenter = { costcenter: group, values: [] };
    for (const item of byCostcenter[group]) {
      const user = lookup[item.userId];
      const mapped = map.find((x) => x.key === item.key);
      if (mapped) {
        groupValues.values.push({
          name: user.name,
          label: mapped.label,
          value: item.value,
          unit: t(`common.${mapped.count}`),
          code: mapped.code
        });
      } else {
        groupValues.values.push({
          name: user.name,
          label: item.key,
          value: item.value,
          unit: '?',
          code: ''
        });
      }
    }
    reportData.push(groupValues);
  }

  return reportData;
};
