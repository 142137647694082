import { useState } from 'react';

export interface SettingsDrawerProps {
  tabs: { label: string }[];
  children: React.ReactNode;
  onChange?: (index: number) => void;
}

export const SettingsDrawer = (props: SettingsDrawerProps) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (index: number) => {
    setTab(index);
    props.onChange?.(index);
  };

  return (
    <div className="grid grid-cols-[100px_1fr]">
      <ul className="pt-1">
        {props.tabs.map((item, i) => (
          <li
            key={i}
            className="cursor-pointer rounded-l-md py-1 pl-2"
            style={{ backgroundColor: tab === i ? '#d4d4d4' : 'inherit' }}
            onClick={handleTabChange.bind(this, i)}
          >
            {item.label}
          </li>
        ))}
      </ul>
      <div className="border-silver-100 max-h-96 min-h-96 overflow-y-scroll rounded-md border px-4 py-2">
        {props.children}
      </div>
    </div>
  );
};
