export interface DialogHdrProps {
  close?: () => void;
  children?: React.ReactNode;
  className?: string;
}

export const DialogHdr = (props: DialogHdrProps) => {
  return (
    <div className={`hidden ${props.children ? 'h-10' : 'h-4'} md:block`}>
      <div className="absolute right-3 top-3">
        <span className="material-icons cursor-pointer" onClick={props.close}>
          close
        </span>
      </div>
      <h2 className="xs:block hidden text-lg font-medium">{props.children}</h2>
    </div>
  );
};
