import {
  IDP,
  IEmailState,
  IGoogleUser,
  IFullUser,
  IUserAllowance
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminUsers {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async active(): Promise<IFullUser[]> {
    return cache.get('admin_user', async () => {
      const result = await this.fetch('api/admin/user');
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }

  async locked(): Promise<IFullUser[]> {
    return cache.get('admin_locked_user', async () => {
      const result = await this.fetch('api/admin/user?locked=true');
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }

  async find(userIds: string[]): Promise<IFullUser[]> {
    if (userIds.length === 0) {
      return [];
    }
    userIds.sort();
    const key = `find_${userIds.join('_')}`;
    return cache.get(key, async () => {
      const response = await this.fetch(
        `/api/admin/user?${userIds.map((id) => `id=${id}`).join('&')}`
      );
      if (response.ok) {
        return response.json();
      }
      return [];
    });
  }

  async google(pattern?: string): Promise<IGoogleUser[]> {
    const response = await this.fetch(
      `/api/admin/google/user?pattern=${pattern || ''}`
    );
    if (response.ok) {
      return response.json();
    }
    return [];
  }

  async update(user: IFullUser): Promise<IFullUser> {
    const body = JSON.stringify(user); // only sends the modified fields
    const response = await this.fetch(`api/admin/user/${user.userId}`, {
      method: 'PUT',
      body
    });
    cache.clear('admin_user');
    cache.clear('admin_locked_user');
    cache.clear(`email_${user.userId}`);
    return response.json();
  }

  async create(user: IFullUser, idp?: IDP | null): Promise<IFullUser | null> {
    const body = JSON.stringify(user);
    const response = await this.fetch(
      `api/admin/user${idp ? `?idp=${idp}` : ''}`,
      {
        method: 'POST',
        body
      }
    );
    if (response.ok) {
      cache.clear('admin_user');
      return response.json();
    } else {
      return null;
    }
  }

  async allowances(
    userId: string
  ): Promise<{ [type: string]: IUserAllowance[] }> {
    return cache.get(`allowances_${userId}`, async () => {
      const response = await this.fetch(`api/admin/user/${userId}/allowances`);
      return response.json();
    });
  }

  async updateAllowances(
    userId: string,
    type: 'hours' | 'holiday',
    allowances: IUserAllowance[]
  ): Promise<IUserAllowance[] | null> {
    const body = JSON.stringify(allowances);
    const response = await this.fetch(
      `api/admin/user/${userId}/allowances?type=${type}`,
      {
        method: 'PUT',
        body
      }
    );
    if (response.ok) {
      cache.clear(`allowances_${userId}`);
      return response.json();
    } else {
      return null;
    }
  }

  async resetPassword(userId: string, password: string) {
    await this.fetch(`api/admin/user/${userId}/password`, {
      method: 'PUT',
      body: JSON.stringify({ password })
    });
  }

  async email(userId: string): Promise<IEmailState> {
    return cache.get(`email_${userId}`, async () => {
      const result = await this.fetch(`api/admin/user/${userId}/email`);
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }
}
