import { RuleBase, RulesEngineResult, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { OverlapMask } from '../core/overlap-mask';
import { InternalAssignment } from '../core/internal-assignment';
import { InternalTest } from '../core/internal-test';

export class NoOverlaps extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSave(from: Date, until: Date): RulesEngineResult {
    console.log('NoOverlap.canSave', from, until);
    return { status: 'ok' };
  }

  canSchedule(
    test: InternalTest,
    plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    const mask = new OverlapMask();
    mask.load(plan, test);
    for (const [day] of plan.entries()) {
      if (mask.overlaps()) {
        //overlap should override other notes
        blocked[day].blocked = true;
        blocked[day].blockOverride = true;
        blocked[day].notes = [
          'Der ausgewählte Dienst würde sich mit dem bestehenden Plan überschneiden.'
        ];
      }
      mask.shiftTestBy({ days: 1 });
    }
  }
}
