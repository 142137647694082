import {
  IRosterRulesConfig,
  IRosterTemplate,
  IRosterTemplateShift
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterConfig {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async template(userId: string | 'my'): Promise<IRosterTemplate> {
    return cache.get(`template-${userId}`, async () => {
      const result = await this.fetch(
        `api/roster/config/template?id=${userId}`
      );
      if (result.status < 300) {
        return result.json();
      }
      return undefined;
    });
  }

  async patterns(groupId: string): Promise<IRosterTemplateShift[]> {
    return cache.get(`patterns-${groupId}`, async () => {
      return (
        await this.fetch(`api/roster/config/pattern?id=${groupId}`)
      ).json();
    });
  }

  async rules(userId?: string | 'my'): Promise<IRosterRulesConfig> {
    return cache.get<IRosterRulesConfig>(`rules-${userId}`, async () => {
      const url = `api/roster/config/rules${userId ? `?id=${userId}` : ''}`;
      return (await this.fetch(url)).json();
    });
  }
}
