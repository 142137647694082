import { ButtonGroup, ToggleButton } from '@kidsmanager/ui-core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';

export const AdminGroupFeaturesRoster = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const { t } = useTranslation('admin');
  const { selected } = context;

  const handleChange = (value: boolean) => {
    if (!selected.features.settings) {
      return;
    }
    selected.features.settings.roster = value
      ? { type: 'monthly' }
      : { type: 'weekly' };
    context.onChange?.();
  };

  const monthly = useMemo(() => {
    return selected.features.settings?.roster.type === 'monthly';
  }, [selected.features.settings?.roster]);

  return (
    <>
      <h1 className="mb-4 text-lg">
        <Link
          className="font-semibold tracking-wide underline"
          to={`/admin/groups/${selected.id}/features`}
        >
          {t('groups.features.title')}
        </Link>
        <span className="mx-2">&gt;</span>
        <span> {t('groups.features.roster.title')}</span>
      </h1>
      <ButtonGroup>
        <ToggleButton active={monthly} onChange={handleChange.bind(this, true)}>
          Monatlich
        </ToggleButton>
        <ToggleButton
          active={!monthly}
          onChange={handleChange.bind(this, false)}
        >
          Wöchentlich
        </ToggleButton>
      </ButtonGroup>
    </>
  );
};
