import { quickid } from '@kidsmanager/ui-api';
import {
  IExportColumn,
  INonWorkMap,
  IReportSettings,
  IRosterTemplate,
  ITimesheetData,
  ITimesheetNonWork
} from '@kidsmanager/util-models';

export interface InternalNonWorkMap extends INonWorkMap {
  label: string;
  tag?: string;
}

export interface NonWorkItem {
  name: string;
  label: string;
  code: string;
  from: string;
  to: string;
  count: number;
}

export interface NonWorkByCostCenter {
  costcenter: string;
  values: NonWorkItem[];
}

export const sources = [
  { key: 'year', label: 'Jahr' },
  { key: 'month', label: 'Monat' },
  { key: 'employeenumber', label: 'Mitarbeiternummer' },
  { key: 'costcenter', label: 'Kostenstelle' },
  { key: 'code', label: 'Buchungscode' },
  { key: 'sub-code', label: 'Untercode' },
  { key: 'amount', label: 'Menge' }
];

export const compileReportData = (
  data: ITimesheetData<ITimesheetNonWork>,
  map: InternalNonWorkMap[]
): NonWorkByCostCenter[] => {
  const result = [] as NonWorkByCostCenter[];

  const lookup = new Map<string, InternalNonWorkMap>();
  for (const item of map) {
    lookup.set(item.key, item);
  }

  for (const item of data.data) {
    const group = getOrCreateByCostcenter(result, item.costcenter);
    const employee = data.employees.find((x) => x.userId === item.userId);
    const map = lookup.get(item.code);
    const code = map
      ? map.subcode
        ? `${map.code}:${map.subcode}`
        : map.code
      : item.code;
    const label = map ? map.label : '-';

    group.values.push({
      name: employee?.name || '???',
      label,
      code,
      from: item.from,
      to: item.to,
      count: item.count
    });
  }
  return result;
};

export const buildNonWorkMap = (
  templates: IRosterTemplate[],
  settings: INonWorkMap[]
) => {
  const items: InternalNonWorkMap[] = [
    { key: 'holiday', label: 'Urlaub', code: '', subcode: '' },
    { key: 'sick', label: 'Krankenstand', tag: 'KS', code: '', subcode: '' }
  ];

  const seenNonWork = new Set<string>();
  templates
    .flatMap((x) => x.nonWork)
    .filter((x) => x.abbreviation)
    .forEach((x) => {
      if (!seenNonWork.has(x.abbreviation)) {
        items.push({
          key: x.abbreviation.toLowerCase(),
          label: x.name,
          tag: x.abbreviation,
          code: '',
          subcode: ''
        });
        seenNonWork.add(x.abbreviation);
      }
    });

  if (settings) {
    for (const item of items) {
      const match = settings.find((x) => x.key === item.key);
      if (match) {
        item.code = match.code;
        item.subcode = match.subcode;
      }
    }
  }

  return items;
};

export const initialiseSettings = (
  templates: IRosterTemplate[],
  settings: IReportSettings,
  t: (key: string) => string
) => {
  const columns: IExportColumn[] =
    settings.exportColumns.length > 0
      ? settings.exportColumns
      : [
          generateSetting('monat', 'month'),
          generateSetting('mitarbeiternummer', 'employeenumber'),
          generateSetting('menge', 'amount'),
          generateSetting('kostenstelle', 'costcenter')
        ];

  const other = settings.other as { map: INonWorkMap[] };
  const map = buildNonWorkMap(templates, other.map);
  return { templates, columns, map };
};

const generateSetting = (label: string, type: string, value?: string) => {
  return { id: quickid(''), label, type, value };
};

const getOrCreateByCostcenter = (
  values: NonWorkByCostCenter[],
  costcenter: string
): NonWorkByCostCenter => {
  const group = values.find((x) => x.costcenter === costcenter);
  if (group) {
    return group;
  }
  const newGroup = { costcenter, values: [] };
  values.push(newGroup);
  return newGroup;
};

export const packSettings = (
  exportColumns: IExportColumn[],
  nonWorkMap: INonWorkMap[]
) => {
  const other = { map: [] as INonWorkMap[] };
  other.map = nonWorkMap.map((value) => ({
    key: value.key,
    code: value.code,
    subcode: value.subcode || ''
  }));
  return { exportColumns, other };
};

export const toCsvCell = (
  key: string,
  item: ITimesheetNonWork,
  employee: { employeeId?: string } | undefined,
  date: Date,
  map: { key: string; code: string }[]
) => {
  switch (key) {
    case 'year':
      return date.getFullYear().toString();
    case 'month':
      return date.getMonth().toString();
    case 'employeenumber':
      return employee?.employeeId || '';
    case 'costcenter':
      return item.costcenter;
    case 'code': {
      const mappedCode = map.find((x) => x.key === item.code);
      return mappedCode?.code || '';
    }
    case 'sub-code': {
      return '';
    }
    case 'amount':
      return item.count.toString();
  }
  return '';
};
