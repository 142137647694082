import { quickid } from '@kidsmanager/ui-api';
import { Button, GhostButton, IconButton, Input } from '@kidsmanager/ui-core';
import { IFullUser } from '@kidsmanager/util-models';
import { useState } from 'react';

export interface DialogNewPasswordProps {
  user: IFullUser;
  onCancel?: () => void;
  onSave?: (password: string) => void;
}

export const DialogNewPassword = (props: DialogNewPasswordProps) => {
  const [password, setPassword] = useState(quickid('', 'low'));

  const handleCopy = () => {
    navigator.clipboard.writeText(password);
  };

  const handleChange = (value: string) => {
    value = value.replace(/\s/g, '');
    setPassword(value);
  };

  return (
    <div className="min-h-52 w-[100vw] max-w-sm rounded bg-white px-10 py-14">
      <h1 className="text-center text-2xl">
        {props.user.lastName}, {props.user.firstName}
      </h1>
      <div className="mb-10 mt-6 flex items-end gap-1">
        <Input
          label="Neues Passwort"
          value={password}
          onChange={handleChange.bind(this)}
        />
        <IconButton onClick={handleCopy.bind(this)}>content_copy</IconButton>
      </div>
      <div className="flex justify-center gap-2">
        <GhostButton onClick={props.onCancel?.bind(this)}>
          Abbrechern
        </GhostButton>
        <Button
          onClick={props.onSave?.bind(this, password)}
          disabled={password.length < 8}
        >
          Passwort Speichern
        </Button>
      </div>
    </div>
  );
};
