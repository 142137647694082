import { IBasicUser, IUserGroup } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiUser {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async find(userId?: string): Promise<IBasicUser> {
    return cache.get(`user-${userId}`, async () => {
      return (await this.fetch(`api/timesheet/users?id=${userId}`)).json();
    });
  }

  async byPrimaryGroup(filter?: 'withRoster'): Promise<IUserGroup[]> {
    const queryParams = filter ? `?filter=${filter}` : '';
    return cache.get('active-users', async () => {
      return (await this.fetch(`api/timesheet/users${queryParams}`)).json();
    });
  }
}
