import { FormEvent, useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  Checkbox,
  IconGoogle,
  Input,
  LoadSpinner,
  Logo
} from '@kidsmanager/ui-core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SsoLoginMessage } from '../feature-google-callback/feature-google-callback';
import { redirectUrl, unlinkWithLegacy } from '../helpers/linking';
import { useTranslation } from 'react-i18next';

export interface FeatureLoginProps {
  brand: 'kidsmgr' | 'my';
  linkWithLegacy?: boolean;
}

export const FeatureLogin = (props: FeatureLoginProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const client = useContext(ClientBackendContext);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const returnUrl = params.get('returnUrl') || '/';

  useEffect(() => {
    const tenant = localStorage.getItem('tenant');
    if (tenant && props.linkWithLegacy) {
      console.log('Unlinking legacy account...');
      unlinkWithLegacy(tenant, () => {
        client.auth.logout();
      });
    }
  }, [client, props.linkWithLegacy]);

  useEffect(() => {
    if (localStorage.getItem('refreshToken') !== null) {
      navigate(returnUrl);
    } else if (params.get('tenant')) {
      setUsername(`${params.get('tenant') || ''}\\`);
    }
  }, [navigate, returnUrl, params]);

  const handleUsernameChange = (value: string) => {
    const fixedUsername = value.replace(/[/]/g, '\\');
    setUsername(fixedUsername);
  };

  const handleUsernameBlur = () => {
    if (username.length > 0 && !username.includes('\\')) {
      setErrorMessage('Bitte Firma \\ Benutzername eingeben');
    } else {
      setErrorMessage('');
    }
  };

  const invalidLogindata = () => {
    const validUsernameRegex = /^[a-zA-Z]{2,}\\.{2,}$/;
    return !username || !password || !validUsernameRegex.test(username);
  };

  const handleLegacyLogin = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const { state, challenge, type } =
      await client.auth.authenticateUsernamePassword(
        username,
        password,
        rememberMe
      );
    switch (state) {
      case 'MfaChallenge':
        sessionStorage.setItem('mfaChallenge', challenge || '');
        navigate(
          `/login/mfa/${type}?returnUrl=${encodeURIComponent(returnUrl)}`
        );
        break;
      case 'Authenticated':
        navigate(redirectUrl(props.linkWithLegacy, returnUrl));
        break;
      case 'EmailUnverified':
        setLoading(false);
        setErrorMessage('E-Mail-Verifizierung notwendig');
        break;
      default:
        setLoading(false);
        setErrorMessage('Login fehlgeschlagen');
        break;
    }
  };

  const handleGoogleLogin = () => {
    const completeGoogleLogin = async ({ data }: { data: SsoLoginMessage }) => {
      if (data.source !== 'kidsmanager') {
        return;
      }
      window.removeEventListener('message', completeGoogleLogin);
      if (data?.success) {
        console.log('Completing Google login...');
        const { token, refreshToken, tenant } = data;
        client.auth.updateTokens(token, refreshToken);
        client.auth.updateTenant(tenant);
        localStorage.setItem('idp', 'google');
        navigate(redirectUrl(props.linkWithLegacy, returnUrl));
      } else {
        console.error('Google login failed', data);
      }
    };

    const width = 600;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const windowProps = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    const tenant = localStorage.getItem('sso-tenant');
    const origin = window.location.origin;
    console.log(`Start Google login for ${tenant}`);
    client.apiOauth2.googleWorkspaceUrl(tenant, origin).then((url) => {
      window.addEventListener('message', completeGoogleLogin);
      setTimeout(() => window.open(url.url, '', windowProps), 100);
    });
  };

  return (
    <div className="mx-4 pt-44 text-center">
      <div className="mx-auto max-w-md px-2">
        <h1 className="flex items-center text-4xl">
          <Logo brand={props.brand} />
        </h1>
        <form
          className="flex max-w-md flex-col gap-2 pt-2"
          onSubmit={handleLegacyLogin.bind(this)}
        >
          <Input
            id="username"
            autocomplete="username"
            placeholder={t('login.placeholder.username')}
            value={username}
            onChange={handleUsernameChange.bind(this)}
            onBlur={handleUsernameBlur.bind(this)}
          />
          <Input
            id="password"
            autocomplete="password"
            placeholder={t('login.placeholder.password')}
            type="password"
            value={password}
            onChange={setPassword.bind(this)}
          />
          <div className="text-left text-sm">
            <Checkbox
              checked={rememberMe}
              onChange={(value) => setRememberMe(value)}
            >
              {t('login.rememberMe')}
            </Checkbox>
          </div>
          <div className="flex items-center justify-end">
            <LoadSpinner show={loading}>
              <Button disabled={invalidLogindata()}>{t('login.submit')}</Button>
            </LoadSpinner>
          </div>
        </form>
        <div className="h-10">
          {errorMessage && <p className="text-error">{errorMessage}</p>}
        </div>
        <div className="mx-auto mb-5 inline-flex items-center">
          <div className="w-20 border-b border-black" />
          <div className="mx-2">{t('login.alternate')}</div>
          <div className="w-20 border-b border-black" />
        </div>
        <div>
          <button
            className="outline-focus inline-flex items-center gap-3 rounded-md border border-black/30 px-6 py-3 hover:bg-[#f0f4f9] active:bg-[#e9edf2]"
            onClick={() => handleGoogleLogin()}
          >
            <IconGoogle /> Google Workspace
          </button>
        </div>
      </div>
    </div>
  );
};
